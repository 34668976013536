/* eslint-disable no-param-reassign */
/* eslint-disable functional/immutable-data */
import { MealPlanType, TasteProfile } from 'src/gqlReactTypings.generated.d';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createSelectors } from './create-selectors';

interface State {
  tasteProfile?: TasteProfile;
  mealPlanType?: MealPlanType;
  isReusablePackaging?: boolean;
  nextDeliveryDate?: string;
  zipCode?: string;
}

interface Actions {
  setIsReusablePackaging: (isReusablePackaging: boolean) => void;
  setMealPlanType: (mealPlanType: MealPlanType) => void;
  setTasteProfile: (tasteProfile: TasteProfile) => void;
  updateTasteProfile: (tasteProfileUpdate: Partial<TasteProfile>) => void;
  setNextDeliveryDate: (nextDeliveryDate: string) => void;
  setZipCode: (zipCode: string) => void;
  getIsReusablePackaging: () => boolean | undefined;
  getMealPlanType: () => MealPlanType | undefined;
  getTasteProfile: () => TasteProfile | undefined;
  getNextDeliveryDate: () => string | undefined;
  getZipCode: () => string | undefined;
}

// Initialize a default state
export const INITIAL_STATE: State = {
  tasteProfile: undefined,
  mealPlanType: undefined,
  isReusablePackaging: undefined,
  nextDeliveryDate: undefined,
};

export const useConsumerMealPlanStore = create(
  persist(
    immer<State & Actions>((set, get) => ({
      ...INITIAL_STATE,
      setMealPlanType: (mealPlanType: MealPlanType): void => {
        set({ mealPlanType });
      },
      setTasteProfile: (tasteProfile: TasteProfile): void => {
        set({ tasteProfile });
      },
      updateTasteProfile: (tasteProfileUpdate: Partial<TasteProfile>): void => {
        set({ tasteProfile: { ...get().tasteProfile, ...tasteProfileUpdate } });
      },
      setIsReusablePackaging: (isReusablePackaging: boolean): void => {
        set({ isReusablePackaging });
      },
      setNextDeliveryDate: (nextDeliveryDate: string): void => {
        set({ nextDeliveryDate });
      },
      setZipCode: (zipCode: string): void => {
        set({ zipCode });
      },
      getIsReusablePackaging: (): boolean | undefined => {
        const { isReusablePackaging } = get();
        return isReusablePackaging;
      },
      getMealPlanType: (): MealPlanType | undefined => {
        const { mealPlanType } = get();
        return mealPlanType;
      },
      getTasteProfile: (): TasteProfile | undefined => {
        const { tasteProfile } = get();
        return tasteProfile;
      },
      getNextDeliveryDate: (): string | undefined => {
        const { nextDeliveryDate } = get();
        return nextDeliveryDate;
      },
      getZipCode: (): string | undefined => {
        const { zipCode } = get();
        return zipCode;
      },
    })),
    {
      name: 'consumer-meal-plan-storage', // unique name
      getStorage: () => localStorage,
    }
  )
);

export const useConsumerMealPlanStoreSelectors = createSelectors(useConsumerMealPlanStore);
