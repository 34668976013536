import { ClientEvents } from 'common/events/ClientEvents';
import React, { useEffect } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { ChatProvider } from 'src/chat/ChatProvider';
import { useNativeApp } from 'src/mobile-app/hooks/useNativeApp';
import { DishFirstCartPositionProvider } from 'src/pages/consumer/components/ConsumerHeaderWrapper';
import { ConsumerRouter } from 'src/pages/consumer/ConsumerRouter';
import { PreCheckoutUpsellModalProvider } from 'src/pages/consumer/dish-first/explore/components/PreCheckoutUpsellModal';
import { HelpButton } from 'src/shared/design-system/HelpButton';
import { ToastContainer } from 'src/shared/design-system/Toast';
import { useSendUserInfoToGoogleTagManager } from 'src/shared/hooks/useSendUserInfoToGoogleTagManager';
import { useTracker } from 'src/shared/hooks/useTracker';
import { useUtm } from 'src/shared/hooks/useUtm';
import { useGlobalReducer, useScopedReducer } from 'src/shef-global-state/shefGlobalState';
import { useFetchGlobalState } from 'src/shef-global-state/useFetchGlobalState';
import { useDispatch } from 'src/store';
import { HistoryProvider } from 'src/util/history';
import { lazyRetry } from 'src/util/retry';
import { StripeProvider } from 'src/vendor/stripe/StripeProvider';
import { ScreenContextProvider } from 'web-common/src/shared/context/ScreenContext/Provider';
import { LoadingScreen } from 'web-common/src/shared/design-system/components/LoadingScreen';
import { Debugger } from './experimentation/ExperimentDebugger';
import { NativeAppContext } from './mobile-app/hooks/useNativeAppContext';
import { GlobalImpersonationHeader } from './pages/components/GlobalImpersonationHeader';
import { BulkDiscountModalProvider } from './pages/consumer/dish-first/explore/components/BulkDiscountModal';
import { MealPlanSubscriptionProvider } from './pages/consumer/meal-plans/MealPlanSubscriptionsContext';
import { useApiSync } from './shared/hooks/useApiSyncHook';
import { useScrollToAnchor } from './shared/hooks/useScrollToAnchor';
import { useScrollToTopOnHistoryChange } from './shared/hooks/useScrollToTopOnHistoryChange';
import { Routes } from './shared/Routes';
import { isDevEnvironment } from './shared/utils/EnvironmentUtilities';
import './styles/scss/app.scss';
import './styles/vendor/tailwind.css';
import { TrackedRouteWithErrorBoundary } from './tracking/tracked-route';

const ShefRouter = lazyRetry(() => import('./pages/shef/ShefRouter'));
const AdminRouter = lazyRetry(() => import('./pages/admin/AdminRouter'));
const PhotoEditorRouter = lazyRetry(() => import('./pages/photo-editors/PhotoEditorRouter'));
const Logout = lazyRetry(() => import('./pages/Logout'));

interface IAppProps {
  match?: RouteChildrenProps['match'];
  history: RouteChildrenProps['history'];
  location: RouteChildrenProps['location'];
}

function App({ history, location }: IAppProps) {
  const { state } = useGlobalReducer();
  const { currentUser, isImpersonating } = state;
  const tracker = useTracker();
  const dispatch = useDispatch();
  useScrollToTopOnHistoryChange();
  useScrollToAnchor();
  useFetchGlobalState({ dispatch }).catch(console.error);
  useUtm({ location });

  const { state: configs } = useScopedReducer('configs');
  const [needToUpdate] = useApiSync(history);

  useEffect(() => {
    if (!configs.apiSyncEnabled || !needToUpdate) {
      return;
    }

    tracker.track(ClientEvents.API_SYNC_RELOAD, {});
    window.location.reload();
  }, [configs.apiSyncEnabled, needToUpdate, tracker]);

  useSendUserInfoToGoogleTagManager();

  const nativeApp = useNativeApp();

  return (
    <ScreenContextProvider>
      <HistoryProvider history={history}>
        <StripeProvider>
          <NativeAppContext.Provider value={nativeApp}>
            <ChatProvider>
              <DishFirstCartPositionProvider>
                <BulkDiscountModalProvider>
                  <PreCheckoutUpsellModalProvider>
                    <ToastContainer autoClose={3000} hideProgressBar pauseOnHover closeOnClick />
                    {Boolean(isDevEnvironment() || currentUser?.admin) && <Debugger />}
                    {isImpersonating && <GlobalImpersonationHeader />}
                    <React.Suspense fallback={<LoadingScreen />}>
                      <Switch>
                        <TrackedRouteWithErrorBoundary path={Routes.LOGOUT} component={Logout} />
                        <Route path={Routes.PHOTO_EDITOR} component={PhotoEditorRouter} />
                        <Route path={Routes.ADMIN} component={AdminRouter} />
                        <Route path={Routes.SHEF} component={ShefRouter} />
                        <MealPlanSubscriptionProvider>
                          <Route path={Routes.CONSUMER_LANDING} component={ConsumerRouter} />
                        </MealPlanSubscriptionProvider>
                      </Switch>
                    </React.Suspense>
                    <HelpButton />
                  </PreCheckoutUpsellModalProvider>
                </BulkDiscountModalProvider>
              </DishFirstCartPositionProvider>
            </ChatProvider>
          </NativeAppContext.Provider>
        </StripeProvider>
      </HistoryProvider>
    </ScreenContextProvider>
  );
}

export default App;
