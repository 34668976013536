import cn from 'classnames';
import { MealPlanAnalyticsLocation } from 'common/events/ClientEvents';
import { isNil } from 'lodash';
import React from 'react';
import { getTopKCuisines } from 'src/shared/design-system/DishFirstCard/utils';
import { useCartStoreSelectors } from 'src/ztore/cart-store';
import { Icon, IconColor } from 'web-common/src/shared/design-system/components/Icon';
import { FoodItemRatings } from '../components/FoodItemRatings';
import { UpsellPriceChip } from '../components/UpsellChips';
import { ServingChip } from './MealPlanDishCard';
import { MealPlanDishImage } from './MealPlanDishImage';
import { MealPlanQuantityPicker } from './MealPlanQuantityPicker';
import { DishCardDisplayMode, MealPlanFoodItem, MealPlanShefSegment } from './types';

const IMAGE_WIDTH_CAROUSEL = 308;
const IMAGE_WIDTH_GRID = 800;
const IMAGE_HEIGHT_CAROUSEL = 200;
const IMAGE_HEIGHT_GRID = 500;

export type MealPlanDishCardUserPreferences = {
  requiredNumber: number;
  servingsPerMeal: number;
};

interface IProps {
  displayMode: DishCardDisplayMode;
  buttonsDisabled: boolean;
  foodItem: MealPlanFoodItem;
  foodItemIndex: number;
  hideServingCount?: boolean;
  remainingAvailableUnits: number;
  servingsPerMeal: number;
  shefIndex?: number;
  shefSegment: MealPlanShefSegment;
  openDishModal: () => void;
  analyticsLocation: MealPlanAnalyticsLocation;
}

export const MealPlanDishCardLarge: React.FC<IProps> = ({
  displayMode,
  buttonsDisabled,
  foodItem,
  foodItemIndex,
  hideServingCount,
  remainingAvailableUnits,
  servingsPerMeal,
  shefIndex,
  shefSegment,
  openDishModal,
  analyticsLocation,
}) => {
  const { cart } = useCartStoreSelectors();
  const isGridDisplay = displayMode === DishCardDisplayMode.Grid;
  const isCarouselDisplay = displayMode === DishCardDisplayMode.Carousel;

  const imageHeight = isGridDisplay ? IMAGE_HEIGHT_GRID : IMAGE_HEIGHT_CAROUSEL;
  const imageWidth = isGridDisplay ? IMAGE_WIDTH_GRID : IMAGE_WIDTH_CAROUSEL;

  const { id, imageUrl, shef, upsell, cuisineCategories, name } = foodItem;
  const unitsInCart = cart[shef.id]?.cartItems.find((item) => item.foodItem.id === foodItem.id)?.quantity ?? 0;

  const upsellPriceChip = <UpsellPriceChip upsell={upsell} servingsPerMeal={servingsPerMeal} />;

  const cuisines = getTopKCuisines({ cuisineCategories, k: 2 });

  return (
    <div
      onClick={openDishModal}
      key={id}
      className={cn(
        `flex cursor-pointer flex-col items-center justify-between self-start`,
        'overflow-hidden rounded-md border border-neutral-100 bg-white p-0 text-left shadow-lg',
        {
          'mb-4 h-[328px] w-[308px] shrink-0 grow-0 basis-[308px]': isCarouselDisplay,
          'w-full min-[600px]:h-[528px] md:h-auto': isGridDisplay,
        }
      )}>
      <MealPlanDishImage
        className={cn({
          'max-h-[400px]': isGridDisplay,
          'h-[204px]': isCarouselDisplay,
        })}
        aspectRatio={isGridDisplay ? '3/2' : undefined}
        height={imageHeight}
        width={imageWidth}
        imageUrl={imageUrl ?? ''}
        remainingAvailableUnits={remainingAvailableUnits}
        unitsInCart={unitsInCart}
        upsell={upsell}
      />
      <div className='flex w-full grow-0 flex-col justify-between p-4'>
        <div className='flex flex-col items-start justify-start'>
          {cuisines && <p className='m-0 line-clamp-1 text-caption-400 text-eggplant-500'>{cuisines}</p>}
          <p className='m-0 line-clamp-1 text-h5-bold text-eggplant'>{name}</p>
          <div className='flex items-center'>
            <Icon name='thumbsUp' size={16} color={IconColor.EGGPLANT_400} />
            <FoodItemRatings
              ratingCount={foodItem.ratingCount}
              averageRating={foodItem.averageRating}
              foodItemId={foodItem.id}
            />
          </div>
        </div>
        <div className='flex w-full justify-between align-baseline'>
          <div className='flex items-center gap-2'>
            {!hideServingCount && <ServingChip numServings={servingsPerMeal} />}
            {upsellPriceChip}
          </div>
          <div className='flex justify-end'>
            <MealPlanQuantityPicker
              outline={false}
              foodItem={foodItem}
              value={unitsInCart}
              disabled={buttonsDisabled}
              shefSegment={shefSegment}
              numServings={servingsPerMeal}
              shefPosition={isNil(shefIndex) ? undefined : shefIndex + 1}
              foodItemPosition={foodItemIndex + 1}
              analyticsLocation={analyticsLocation}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
