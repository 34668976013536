import { ReactComponent as cuisineAfrican } from './cuisineAfrican.svg';
import { ReactComponent as cuisineAllFood } from './cuisineAllFood.svg';
import { ReactComponent as cuisineAmerican } from './cuisineAmerican.svg';
import { ReactComponent as cuisineBakedGoods } from './cuisineBakedGoods.svg';
import { ReactComponent as cuisineCaribbean } from './cuisineCaribbean.svg';
import { ReactComponent as cuisineChinese } from './cuisineChinese.svg';
import { ReactComponent as cuisineEastIndian } from './cuisineEastIndian.svg';
import { ReactComponent as cuisineFusion } from './cuisineFusion.svg';
import { ReactComponent as cuisineGeneric } from './cuisineGeneric.svg';
import { ReactComponent as cuisineIndian } from './cuisineIndian.svg';
import { ReactComponent as cuisineIndoChinese } from './cuisineIndoChinese.svg';
import { ReactComponent as cuisineItalian } from './cuisineItalian.svg';
import { ReactComponent as cuisineJapanese } from './cuisineJapanese.svg';
import { ReactComponent as cuisineKorean } from './cuisineKorean.svg';
import { ReactComponent as cuisineLatinAmerican } from './cuisineLatinAmerican.svg';
import { ReactComponent as cuisineMediterranean } from './cuisineMediterranean.svg';
import { ReactComponent as cuisineMexican } from './cuisineMexican.svg';
import { ReactComponent as cuisineMiddleEastern } from './cuisineMiddleEastern.svg';
import { ReactComponent as cuisineNorthIndian } from './cuisineNorthIndian.svg';
import { ReactComponent as cuisinePakistani } from './cuisinePakistani.svg';
import { ReactComponent as cuisineSoutheastAsian } from './cuisineSoutheastAsian.svg';
import { ReactComponent as cuisineSouthern } from './cuisineSouthern.svg';
import { ReactComponent as cuisineSouthIndian } from './cuisineSouthIndian.svg';
import { ReactComponent as cuisineWestIndian } from './cuisineWestIndian.svg';

export default {
  cuisineAfrican,
  cuisineAllFood,
  cuisineAmerican,
  cuisineBakedGoods,
  cuisineCaribbean,
  cuisineChinese,
  cuisineEastIndian,
  cuisineFusion,
  cuisineGeneric,
  cuisineIndian,
  cuisineIndoChinese,
  cuisineItalian,
  cuisineJapanese,
  cuisineKorean,
  cuisineLatinAmerican,
  cuisineMediterranean,
  cuisineMexican,
  cuisineMiddleEastern,
  cuisineNorthIndian,
  cuisinePakistani,
  cuisineSoutheastAsian,
  cuisineSouthern,
  cuisineSouthIndian,
  cuisineWestIndian,
};
