import { noOp } from 'common/Constants';
import { formatGqlErrors } from 'common/GqlUtilities';
import { useCallback, useEffect } from 'react';
import { GlobalInitialStateDocument, GlobalInitialStateQuery } from 'src/gqlReactTypings.generated.d';
import { useGqlClient } from 'src/shared/useGqlClient';
import { Store } from 'src/store';
import { setAvailableAddOns } from 'src/store/cart/actions';
import { getToken } from '../shared/storage/Storage';
import { useCachedZipCode } from '../user-preferences/useCachedZipCode';
import { useGlobalReducer } from './shefGlobalState';

// TODO: Move configs / other things we want done only once into this file
export const useFetchGlobalState = ({ dispatch }: { dispatch: Store['dispatch'] }) => {
  const { reduce: globalReduce } = useGlobalReducer();
  const userIsLoggedIn = Boolean(getToken());
  const GqlClient = useGqlClient();
  const [cachedZipCode, setCachedZipCode] = useCachedZipCode();

  // Return a promise that can be used to know when fetch is done.
  let promiseResolve: (value?: any) => void = useCallback(noOp, []);
  const fetchPromise = new Promise((resolve) => {
    promiseResolve = resolve;
  });

  useEffect(() => {
    globalReduce((state) => ({ ...state, loading: true }));

    GqlClient.query<GlobalInitialStateQuery>({
      query: GlobalInitialStateDocument,
      variables: { zipCode: cachedZipCode },
      fetchPolicy: 'network-only',
    })
      .then(({ data, errors }) => {
        if (errors?.length) {
          console.error(formatGqlErrors(errors));
          return;
        }

        globalReduce((state) => ({
          ...state,
          errors,
          loaded: true,
          currentUser: data.me ?? null,
          userRegionId: data.zipCode?.regionId ?? null,
          userRegionName: data.zipCode?.region?.name ?? null,
          region: data.zipCode?.region ?? null,
          timeZone: data.zipCode?.timeZone ?? null,
          minimumAmountForDiscountedShipping: data.zipCode?.minimumAmountForDiscountedShipping ?? null,
          discountedDeliveryFee: data.zipCode?.discountedDeliveryFee ?? null,
          deliveryFee: data.zipCode?.deliveryFee ?? null,
          configs: {
            ...state.configs,
            shefRatingEnabled: data.shefRatingEnabled,
            appleSignInOnWebEnabled: data.appleSignInOnWebEnabled,
            googleSignInOAuth2Enabled: data.googleSignInOAuth2Enabled,
            upsellsEnabled: data.upsellsEnabled,
            apiSyncEnabled: data.apiSyncEnabled,
            profilePaymentMethodsEnabled: data.profilePaymentMethodsEnabled,
            profileDeliveryAddressesEnabled: data.profileDeliveryAddressesEnabled,
            rateRecentOrderPopupEnabled: data.rateRecentOrderPopupEnabled,
            giftCardPurchaseEnabled: data.giftCardPurchaseEnabled,
            holidayMenusEnabled: data.holidayMenusEnabled,
            foodItemAudits: data.foodItemAudits,
            shefPlatformUsageQuestionnaire: data.shefPlatformUsageQuestionnaire,
            dishwasher: data.dishwasher,
            shefDashboardInsights: data.shefDashboardInsights,
            requestAppReviewsEnabled: data.requestAppReviewsEnabled,
            shefMenuChecklistEnabled: data.shefMenuChecklistEnabled,
            shefPostsEnabled: data.shefPostsEnabled,
            enableAdminPages: !data.disableAdminPages,
            chatEnabled: data.chatEnabled,
            dishFirstNav: data.dishFirstNav,
            shefApplicationAlwaysEnableContinueInformationSessionVideo:
              data.shefApplicationAlwaysEnableContinueInformationSessionVideo,
            consumerDeleteUserEnabled: data.consumerDeleteUserEnabled,
            shefGrowYourBusinessEnabled: data.shefGrowYourBusinessEnabled,
            personalShefEnabled: data.personalShefEnabled,
            shefSideCustomizations: data.shefSideCustomizations,
            fringeOverrideEnabled: data.fringeOverrideEnabled,
            bulkDiscountEnabled: data.bulkDiscountEnabled,
            chatSupportKeywords: JSON.parse(data.chatSupportKeywords ?? ''),
            chatFeedbackKeywords: JSON.parse(data.chatFeedbackKeywords ?? ''),
            chatCustomizationKeywords: JSON.parse(data.chatCustomizationKeywords ?? ''),
            chatBusinessRiskKeywords: JSON.parse(data.chatBusinessRiskKeywords ?? ''),
            upfrontDeliveryFeeEnabled: data.zipCode?.upfrontDeliveryFeeEnabled ?? false,
            mealPlansEnabled: data.mealPlansEnabled,
            mealPlansAllergensEnabled: data.mealPlansAllergensEnabled,
            mealPlansBrowseAsGuest: data.mealPlansBrowseAsGuest,
            showManageMealPlans: data.showManageMealPlans,
            flexibleSidesEnabled: data.flexibleSidesEnabled,
            isIntakeRevampEnabled: data.isIntakeRevampEnabled,
            reusablePackagingEnabled: data.reusablePackagingEnabled,
            shefRosterEnabled: data.shefRosterEnabled,
            EmptyHeldOrdersEnabled: data.emptyHeldOrdersEnabled,
            MealPlanModificationEnabled: data.mealPlanModificationEnabled,
          },
        }));

        // If an existing user signs in from a new browser, let's update the browser cache
        // (unless they've overwritten this locally)
        if (!cachedZipCode && data.me?.lastSearchedZipCode) {
          setCachedZipCode(data.me?.lastSearchedZipCode);
        }
        dispatch(setAvailableAddOns({ availableAddOnItems: data.availableAddOnItems }));
      })
      .catch((err) => {
        globalReduce((state) => ({
          ...state,
          errors: [err.toString()],
          loaded: true,
        }));

        console.error(err);
      })
      .finally(() => {
        globalReduce((state) => ({ ...state, loading: false }));
        promiseResolve();
      })
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalReduce, setCachedZipCode, cachedZipCode, userIsLoggedIn, GqlClient]);
  return fetchPromise;
};
