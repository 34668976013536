import { isAndroidDevice, isFacebookBrowser, isFacebookMessenger, isInstagramBrowser } from 'common/BrowserUtilities';
import { ClientEvents } from 'common/events/ClientEvents';
import React, { useRef } from 'react';
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useLoginOrSignUpWithOAuth } from 'src/auth/oAuth/useLoginOrSignUpWithOAuth';
import { OAuthLoginType, OAuthMissingFieldsResult } from 'src/gqlReactTypings.generated.d';
import { LoginButtonV2 } from 'src/shared/design-system/Login/LoginButtonV2';
import { useOnMount } from 'src/shared/hooks/useOnMount';
import { useTracker } from 'src/shared/hooks/useTracker';
import { getParam } from 'src/shared/utils/RouteUtilities';
import { v4 as uuidv4 } from 'uuid';
import { decodeOAuthState, getEncodedOAuthState, isOAuthStateMatching } from './oAuthState';

const FB_USER_INFO_FIELDS = { id: '', email: '', first_name: '', last_name: '' };

interface IFacebookLoginV2Props {
  onSuccess?: (isExistingUser: boolean) => void;
  onError: (error: Error) => void;
  onAttempt?: () => void;
  onMissingFields: (type: OAuthLoginType, token: string, missingFieldsResult: OAuthMissingFieldsResult) => void;
  requireZipCode?: boolean;
  signUpEntryPoint?: string | null;
  renderButton?: ({
    onClick,
    isDisabled,
  }: {
    onClick: React.MouseEventHandler;
    isDisabled?: boolean;
  }) => React.ReactNode;
}

const useMobileRedirect = () => {
  const facebookOnAndroid = isAndroidDevice(navigator.userAgent) && isFacebookBrowser(navigator.userAgent);
  return facebookOnAndroid || isFacebookMessenger(navigator.userAgent) || isInstagramBrowser(navigator.userAgent);
};

export const FacebookLoginV2: React.FC<IFacebookLoginV2Props> = ({
  onSuccess,
  onError,
  onAttempt,
  onMissingFields,
  requireZipCode,
  signUpEntryPoint,
  renderButton,
}) => {
  const tracker = useTracker();
  if (typeof process.env.REACT_APP_FACEBOOK_APP_ID === 'undefined') {
    throw new Error('Missing env variable: REACT_APP_FACEBOOK_APP_ID');
  }

  const { login, handleError } = useLoginOrSignUpWithOAuth({
    type: OAuthLoginType.Facebook,
    onError,
    onSuccess,
    onMissingFields,
    requireZipCode,
    signUpEntryPoint,
  });
  const uuid = useRef<string>(uuidv4());

  useOnMount(() => {
    const token = getParam(window.location, 'access_token');
    if (token) {
      if (onSuccess) {
        onSuccess(true);
      }
      return;
    }

    // We got a redirect back from facebook.
    if (window.location.hash) {
      // Parse data
      const params = new URLSearchParams(window.location.hash.slice(1));
      const token = params.get('access_token');
      const encodedState = params.get('state');
      const state = decodeOAuthState(encodedState);

      if (!isOAuthStateMatching(state, OAuthLoginType.Facebook, uuid.current)) {
        return;
      }

      // Redirect to page that started this with the token
      if (token && state.redirect) {
        const redirectUrl = new URL(state.redirect);
        redirectUrl.searchParams.set('access_token', token);
        window.location.href = redirectUrl.toString();
      }
    }
  });

  const handleLoginFailure = (failureResponse: ReactFacebookFailureResponse) => {
    handleError(new Error(`Failed to log in with facebook: ${failureResponse.status}`));
  };

  const handleAttempt = () => {
    tracker.track(ClientEvents.SSO_SIGNUP_CLICKED, { sso_name: 'facebook' });
    if (onAttempt) {
      onAttempt();
    }
  };

  const shouldUseMobileRedirect = useMobileRedirect();
  const redirect = shouldUseMobileRedirect ? window.location.toString() : undefined;
  const state = getEncodedOAuthState(OAuthLoginType.Facebook, uuid.current, redirect);
  const mobileRedirectProps = shouldUseMobileRedirect
    ? {
        disableMobileRedirect: false,
        responseType: 'token',
        redirectUri: `${window.location.origin}/login`,
      }
    : {};

  const renderFacebookButton =
    renderButton ??
    (({ onClick, isDisabled }) =>
      isDisabled ? null : (
        <LoginButtonV2 iconName='facebook' onClick={onClick}>
          Facebook
        </LoginButtonV2>
      ));
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      fields={Object.keys(FB_USER_INFO_FIELDS).join(',')}
      callback={(userInfo: ReactFacebookLoginInfo) => login(userInfo.accessToken)}
      onClick={handleAttempt}
      onFailure={handleLoginFailure}
      autoLoad={false}
      render={renderFacebookButton}
      disableMobileRedirect={true}
      state={state}
      {...mobileRedirectProps}
    />
  );
};
