import { DEFAULT_REFERRER_VALUE } from 'common/Constants';
import { LoginModalDismissElements } from 'common/events/ClientEvents';
import {
  DEFAULT_NEW_USER_CODE,
  NEW_USER_CODE_FOR_HUBLESS_REGIONS,
  NEWBIE_PROMO_CODE_FIRST_30_FREE_DELIVERY,
  NEWBIE_PROMO_CODE_FIRST_50_FREE_DELIVERY,
} from 'common/PromoCodeUtilities';
import { StorageKeys } from 'common/storage/constants';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import referralPromoBanner from 'src/assets/svg/referral-promo-banner.svg';
import signUpPromo10 from 'src/assets/svg/sign-up-promo-10.svg';
import signUpPromo15 from 'src/assets/svg/sign-up-promo-15.svg';
import signUpPromo30 from 'src/assets/svg/sign-up-promo-30.svg';
import signUpPromo50 from 'src/assets/svg/sign-up-promo-50.svg';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';

import { formatCurrency } from 'common/FormatUtilities';
import blurredShefRoster from 'src/assets/img/sign-up-modal/blurred-shef-roster.png';
import { CELEBRITY_EVENT_PROMO_BANNER } from 'src/celebrity-event/Content';
import { CelebrityEventContext } from 'src/celebrity-event/context/CelebrityEventProvider';
import { OAuthLoginType, OAuthMissingFieldsResult, PromoCodeType } from 'src/gqlReactTypings.generated.d';
import { ShefLogo } from 'src/pages/consumer/components/header-v2/shared-components/ShefLogo';
import {
  EllipsedEdgeColor,
  EllipsedEdgeContainer,
} from 'src/pages/consumer/dish-first/explore/components/MealPlan/EllipsedEdgeContainer';
import { CheckoutFormUrls } from 'src/pages/consumer/meal-plans/checkout/consts';
import { usePromotionalsHook } from 'src/promotionals/usePromotionalsHook';
import { Header2 } from 'src/shared/design-system/Headers';
import { ArrowSoftLeftV2, Close } from 'src/shared/design-system/Icon';
import { Routes } from 'src/shared/Routes';
import { useLocalStorage } from 'src/shared/storage/Storage';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { FormType, ModalActionTypes, useLoginOrSignUpModalStore } from 'src/ztore/login-or-signup-modal-store';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import Button, { ButtonType } from 'web-common/src/shared/design-system/components/Button';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';
import { LogInOrSignUpModalEntryForm } from './LoginOrSignUpModalEntryForm';
import { LoginWithEmail } from './LoginWithEmail';
import { MealPlanPromoBanner } from './MealPlanPromoBanner';
import { OAuthMissingFieldsForm } from './OAuthMissingFieldsForm';
import { SignUpWithEmail } from './SignUpWithEmail';
import { useShowLoginOrSignUpModal } from './useShowLoginOrSignUpModal';

const FullScreenHeader = styled(Header2)`
  font-family: ${Font.Family.SANS};
  font-size: ${Font.Size.XL};
  line-height: ${Font.LineHeight.L};
  margin-top: ${Spacing.SINGLE_HALF};
`;

interface LoginOrSignUpModalProps {
  showClose?: boolean;
  onCloseButtonClicked?: (LoginModalDismissElements) => void;
  fullScreenView?: boolean;
  stickyHeader?: boolean;
  reloadOnLogin?: boolean;
  loginOrSignUpModalEntryFormRef: React.RefObject<HTMLFormElement>;
  setModalHeaderText: (headerText: string) => void;
}

export const LoginOrSignUpModalContent: React.FC<LoginOrSignUpModalProps> = ({
  showClose,
  onCloseButtonClicked,
  fullScreenView,
  loginOrSignUpModalEntryFormRef,
  setModalHeaderText,
}) => {
  const [, setStorageKey] = useLocalStorage(StorageKeys.LOGIN_ON_EXPLORE_MODAL, false);
  const {
    signUpPromoCode,
    entryHeader,
    requireZipCode,
    signUpEntryPoint,
    preventReloadOnLogin,
    onSuccessfulLogin,
    signUpCTA,
    continueCTA,
    loginCTA,
    hideBanner,
    hideGuestOption,
  } = useShowLoginOrSignUpModal();
  const { modalState, dispatchModalAction } = useLoginOrSignUpModalStore();
  const { setCode } = usePromotionalsHook();
  const history = useHistory();

  const celebrityEventContext = useContext(CelebrityEventContext);

  const onMealPlanPage = locationInRoutes(history.location, [
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
    Routes.CONSUMER_MEAL_PLANS,
  ]);

  const [{ step }] = useQueryParams({
    step: withDefault(StringParam, undefined),
  });
  const onMealPlanCheckoutStep = step === encodeURIComponent(CheckoutFormUrls.CHECKOUT);

  const promoCodeImageClasses = 'bg-mint-100 rounded-lg';
  const promoCodeImage = match({
    signUpPromoCode,
    celebrityEventType: celebrityEventContext.celebrityEventType,
    onMealPlanPage,
  })
    .with(
      { celebrityEventType: P.not(P.nullish) },
      ({ celebrityEventType }) => CELEBRITY_EVENT_PROMO_BANNER[celebrityEventType]
    )
    .with({ signUpPromoCode: { promoCodeType: PromoCodeType.Referral } }, () => (
      <img
        className={promoCodeImageClasses}
        src={referralPromoBanner}
        alt={`Sign up to receive your referral reward, Claim your ${formatCurrency(
          DEFAULT_REFERRER_VALUE,
          true
        )} welcome offer`}
      />
    ))
    .with({ signUpPromoCode: { code: NEWBIE_PROMO_CODE_FIRST_30_FREE_DELIVERY } }, () => (
      <img className={promoCodeImageClasses} src={signUpPromo30} alt='$30 off your first five orders' />
    ))
    .with({ signUpPromoCode: { code: NEWBIE_PROMO_CODE_FIRST_50_FREE_DELIVERY } }, () => (
      <img className={promoCodeImageClasses} src={signUpPromo50} alt='$50 off your first five orders' />
    ))
    .with(
      {
        onMealPlanPage: true,
      },
      () => <MealPlanPromoBanner />
    )
    .with({ signUpPromoCode: { code: NEW_USER_CODE_FOR_HUBLESS_REGIONS } }, () => (
      <img className={promoCodeImageClasses} src={signUpPromo15} alt='Sign up to receive $15 off your first order' />
    ))
    .with({ signUpPromoCode: { code: DEFAULT_NEW_USER_CODE } }, () => (
      <img className={promoCodeImageClasses} src={signUpPromo10} alt='Sign up to receive $10 off your first order' />
    ))
    .otherwise(() => null);

  const shefRosterImage = match({
    onMealPlanCheckoutStep,
    onMealPlanPage,
  })
    .with({ onMealPlanCheckoutStep: false, onMealPlanPage: true }, () => (
      <img src={blurredShefRoster} className='w-full' />
    ))
    .otherwise(() => null);

  const onEmailContinueSuccess = (emailToContinueWith: string, isRegisteredUser: boolean) => {
    dispatchModalAction({
      type: ModalActionTypes.CONTINUE_WITH_EMAIL,
      email: emailToContinueWith,
      isExistingUser: isRegisteredUser,
    });
  };

  const onEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    dispatchModalAction({ type: ModalActionTypes.SET_EMAIL, email: event.currentTarget.value });
  };

  const goBackToEntry = () => dispatchModalAction({ type: ModalActionTypes.SWITCH_TO_ENTRY });

  const onOAuthMissingFields = (type: OAuthLoginType, token: string, oAuthMissingFields: OAuthMissingFieldsResult) => {
    dispatchModalAction({
      type: ModalActionTypes.SWITCH_TO_MISSING_FIELDS_STATE,
      oAuthType: type,
      token,
      missingFields: oAuthMissingFields,
    });
  };

  // Make sure the customers don't see the login modal on explore page experiment again.
  const onSuccessfulLoginOrSignUp = (isExistingUser: boolean) => {
    setStorageKey(true);

    if (!isExistingUser && signUpPromoCode) setCode(signUpPromoCode);

    if (!preventReloadOnLogin) {
      if (!requireZipCode && !celebrityEventContext.celebrityEventType) window.location.reload();
    } else {
      onSuccessfulLogin?.();
    }
  };

  const {
    header,
    formComponent,
    promoBanner,
    shefRosterBanner,
    closeOrBackButton,
  }: {
    header: string;
    formComponent: React.ReactNode;
    promoBanner: React.ReactNode;
    shefRosterBanner: React.ReactNode;
    closeOrBackButton: React.ReactNode;
  } = match(modalState ?? { formType: 0, email: '' })
    .with({ formType: FormType.REGISTER_WITH_EMAIL }, (registerState) => {
      setModalHeaderText('FINISH SIGNING UP');
      return {
        header: 'FINISH SIGNING UP',
        formComponent: (
          <SignUpWithEmail
            onEmailChange={onEmailChange}
            email={registerState.email}
            onSuccess={onSuccessfulLoginOrSignUp}
            requireZipCode={requireZipCode}
            onUserAlreadyExists={(email, errorMessage) =>
              dispatchModalAction({
                type: ModalActionTypes.CONTINUE_WITH_EMAIL,
                email,
                errorMessage,
                isExistingUser: true,
              })
            }
            signUpPromoCode={signUpPromoCode?.code}
            signUpEntryPoint={signUpEntryPoint}
            signupCTA={signUpCTA}
          />
        ),
        promoBanner: promoCodeImage,
        shefRosterBanner: null,
        closeOrBackButton: (
          <ArrowSoftLeftV2
            className={`color-neutral-800 w-4 ${fullScreenView ? '' : 'absolute'}`}
            cursor='pointer'
            onClick={() => dispatchModalAction({ type: ModalActionTypes.SWITCH_TO_ENTRY })}
          />
        ),
      };
    })
    .with({ formType: FormType.LOGIN_WITH_EMAIL }, (loginState) => {
      setModalHeaderText('LOG IN');
      return {
        header: 'LOG IN',
        formComponent: (
          <LoginWithEmail
            onEmailChange={onEmailChange}
            email={loginState.email}
            onSuccess={onSuccessfulLoginOrSignUp}
            goBack={() => dispatchModalAction({ type: ModalActionTypes.SWITCH_TO_ENTRY })}
            errorMessage={loginState.errorMessage}
            loginCTA={loginCTA}
          />
        ),
        promoBanner: null,
        shefRosterBanner: null,
        closeOrBackButton: (
          <ArrowSoftLeftV2
            className={`color-neutral-800 w-4 ${fullScreenView ? '' : 'absolute'}`}
            cursor='pointer'
            onClick={goBackToEntry}
          />
        ),
      };
    })
    .with({ formType: FormType.OAUTH_MISSING_FIELDS }, (oAuthMissingFieldsState) => {
      setModalHeaderText('FINISH SIGNING UP');
      return {
        header: 'FINISH SIGNING UP',
        formComponent: (
          <OAuthMissingFieldsForm
            type={oAuthMissingFieldsState?.oAuthType ?? OAuthLoginType.GoogleWeb}
            missingFields={oAuthMissingFieldsState?.missingFields ?? {}}
            token={oAuthMissingFieldsState?.token ?? ''}
            onSuccess={onSuccessfulLoginOrSignUp}
            requireZipCode={requireZipCode}
            signUpEntryPoint={signUpEntryPoint}
          />
        ),
        closeOrBackButton: (
          <ArrowSoftLeftV2
            className={`color-neutral-800 w-4 ${fullScreenView ? '' : 'absolute'}`}
            cursor='pointer'
            onClick={goBackToEntry}
          />
        ),
        promoBanner: promoCodeImage,
        shefRosterBanner: null,
      };
    })
    .with({ formType: FormType.ENTRY_POINT }, (entryState) => {
      setModalHeaderText(entryHeader ?? 'LOG IN OR SIGN UP');
      return {
        header: entryHeader ?? 'LOG IN OR SIGN UP',
        formComponent: (
          <LogInOrSignUpModalEntryForm
            email={entryState.email}
            onEmailChange={onEmailChange}
            onEmailContinueSuccess={onEmailContinueSuccess}
            onOAuthMissingFields={onOAuthMissingFields}
            onOAuthSuccess={onSuccessfulLoginOrSignUp}
            requireZipCode={requireZipCode}
            signUpEntryPoint={signUpEntryPoint}
            formCloseButton={
              showClose && !hideGuestOption && onCloseButtonClicked ? (
                <Button
                  onClick={() => onCloseButtonClicked(LoginModalDismissElements.BROWSE_AS_GUEST)}
                  buttonType={ButtonType.TextLink}>
                  {'Browse as Guest'}
                </Button>
              ) : null
            }
            continueCTA={continueCTA ?? 'Continue'}
            formRef={loginOrSignUpModalEntryFormRef}
          />
        ),
        promoBanner: promoCodeImage,
        shefRosterBanner: shefRosterImage,
        closeOrBackButton:
          showClose && onCloseButtonClicked ? (
            <Close
              className={`color-neutral-800 size-3 ${fullScreenView ? '' : 'absolute'}`}
              cursor='pointer'
              onClick={() => onCloseButtonClicked(LoginModalDismissElements.CLOSE_BUTTON)}
              data-cy='login-modal-close-btn'
            />
          ) : null,
      };
    })
    .otherwise(() => {
      // Should not happen
      throw new Error('Missing login modal form state');
    });

  if (fullScreenView) {
    return (
      <>
        <div className='mx-6 mt-6'>
          {closeOrBackButton ?? <ShefLogo color={Colors.RADISH} />}
          <FullScreenHeader>{header}</FullScreenHeader>
        </div>
        {formComponent}
      </>
    );
  }

  return (
    <>
      <div className='w-full overflow-hidden rounded-t-xl'>
        <div className='mb-8 flex w-full flex-col items-center justify-center gap-6 bg-milk px-4 pb-4 lg:px-24'>
          {shefRosterBanner && (
            <div className='flex w-full justify-center sm:h-auto sm:max-w-sm'>{shefRosterImage}</div>
          )}
          {!hideBanner && promoBanner && !shefRosterBanner ? (
            <div className='flex w-full justify-center'>{promoBanner}</div>
          ) : null}
        </div>
      </div>
      <EllipsedEdgeContainer color={EllipsedEdgeColor.SOURDOUGH}>
        <div className='px-4 pb-8 lg:px-24'>{formComponent}</div>
      </EllipsedEdgeContainer>
    </>
  );
};
