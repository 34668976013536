import { ALL_CUISINES, CUISINE_MAPPING } from 'common/Constants';
import { getServingsPerMealForItem } from 'common/MealPlanUtils';
import { flattenDeep, intersection, partition, uniq } from 'lodash';
import { Address, Maybe } from 'src/gqlReactTypings.generated.d';
import { isSameDate } from 'src/util/dates';
import { Cart, MealPlanCartItem } from 'src/ztore/cart-store';
import { MY_MENU_FILTER } from './consts';
import { MealPlanFoodItem, MealPlanShefSegment, ShefSegmentRankGroups, ZipCodeOnly } from './types';

/**
 * Calculates availability based on the item-level and shef-level availabilities.
 *
 * Optionally consider the current cart (based on shef).
 */
interface CalculateShefAndFoodItemAvailabilityInUnitsParams {
  foodItem: MealPlanFoodItem;
  deliveryDate: string;
  shefRemainingAvailability: number;
  mealPlanNumServings: number;
  preEditCartForShef?: MealPlanCartItem[];
  cartForShef?: MealPlanCartItem[];
}

export const hasAddressFields = (deliveryInfo: Address | ZipCodeOnly | undefined): deliveryInfo is Address =>
  !!deliveryInfo &&
  'street' in deliveryInfo &&
  'city' in deliveryInfo &&
  'province' in deliveryInfo &&
  'country' in deliveryInfo;

export const calculateShefAndFoodItemAvailabilityInUnits = (
  params: CalculateShefAndFoodItemAvailabilityInUnitsParams
) => {
  const {
    foodItem,
    deliveryDate,
    shefRemainingAvailability,
    mealPlanNumServings,
    cartForShef = [],
    preEditCartForShef = [],
  } = params;
  const servingsPerMealForItem = getServingsPerMealForItem(foodItem.id, mealPlanNumServings);

  // Availability based on shef
  const totalQuantityInPreEditCartForShef = preEditCartForShef.reduce(
    (total, item) => total + getServingsPerMealForItem(item.foodItem.id, mealPlanNumServings) * (item.quantity ?? 0),
    0
  );
  const totalQuantityInCartForShef = cartForShef.reduce(
    (total, item) => total + getServingsPerMealForItem(item.foodItem.id, mealPlanNumServings) * (item.quantity ?? 0),
    0
  );
  const numUnitsTakenUpByCart =
    (totalQuantityInCartForShef - totalQuantityInPreEditCartForShef) / servingsPerMealForItem;
  const shefRemainingAvailabilityInUnits = shefRemainingAvailability / servingsPerMealForItem;
  const unitsAvailableBasedOnShef = Math.floor(shefRemainingAvailabilityInUnits - numUnitsTakenUpByCart);

  // Availability based on item
  const availabilityObj = foodItem.availability?.find((aObj) => isSameDate(aObj.availabilityDate, deliveryDate));
  const numAvailableUnitsForDate = Math.floor((availabilityObj?.numAvailable ?? 0) / servingsPerMealForItem);

  const unitsInCartOfFoodItem = cartForShef.find((item) => item.foodItem.id === foodItem.id)?.quantity ?? 0;
  const unitsInPreEditCartOfFoodItem =
    preEditCartForShef.find((item) => item.foodItem.id === foodItem.id)?.quantity ?? 0;
  const unitsAvailableBasedOnItem = numAvailableUnitsForDate - unitsInCartOfFoodItem + unitsInPreEditCartOfFoodItem;

  return {
    unitsAvailableBasedOnShef,
    unitsAvailableBasedOnItem,
  };
};

export const getTotalOrderText = (totalOrders: number) =>
  totalOrders < 1000 ? `${totalOrders} orders` : `${Math.round(totalOrders / 100) / 10}k+ orders`;

interface SortFoodItemsByAvailabilityParams {
  foodItems: MealPlanFoodItem[];
  deliveryDate: string;
  shefRemainingAvailability: number;
  mealPlanNumServings: number;
  cartForShef?: MealPlanCartItem[];
  hideUnavailableItems: boolean;
  preEditCartForShef?: MealPlanCartItem[];
}

export type AvailableFoodItem = { foodItem: MealPlanFoodItem; numAvailableUnits: number };

/**
 * Separate available and unavailable items, keeping the original order of the food items (ranked by conversion, etc)
 * within each section.
 *
 * A user may have the last few of a foodItem in cart, and make the foodItem reach max quantity.
 * The logic may consider that as "unavailable", but we'll still sort those to the front.
 *
 * @param params - SortFoodItemsByAvailabilityParams
 * @returns {Array<{ foodItem: MealPlanFoodItem, numAvailableUnits: number }>} - Sorted food items & numAvailableUnits
 */
export const sortFoodItemsByAvailability = ({
  foodItems,
  deliveryDate,
  shefRemainingAvailability,
  mealPlanNumServings,
  cartForShef,
  hideUnavailableItems,
  preEditCartForShef,
}: SortFoodItemsByAvailabilityParams): AvailableFoodItem[] => {
  // First, map all items to include their availability info
  const foodItemsWithAvailability = foodItems.map((foodItem) => {
    const { unitsAvailableBasedOnItem, unitsAvailableBasedOnShef } = calculateShefAndFoodItemAvailabilityInUnits({
      foodItem,
      deliveryDate: deliveryDate ?? '',
      shefRemainingAvailability,
      mealPlanNumServings,
      cartForShef,
      preEditCartForShef,
    });
    return {
      foodItem,
      numAvailableUnits: Math.min(unitsAvailableBasedOnItem, unitsAvailableBasedOnShef),
    };
  });

  // 1. Items in cart
  const itemsInCart = foodItemsWithAvailability.filter(({ foodItem }) =>
    cartForShef?.some((cartItem) => cartItem.foodItem.id === foodItem.id && cartItem.quantity > 0)
  );

  // 2. Available items (not in cart)
  const availableItemsNotInCart = foodItemsWithAvailability.filter(
    ({ foodItem, numAvailableUnits }) =>
      !itemsInCart.some((cartItem) => cartItem.foodItem.id === foodItem.id) && numAvailableUnits > 0
  );

  // 3. Unavailable items (not in cart)
  const unavailableItemsNotInCart = foodItemsWithAvailability.filter(
    ({ foodItem, numAvailableUnits }) =>
      !itemsInCart.some((cartItem) => cartItem.foodItem.id === foodItem.id) && numAvailableUnits <= 0
  );

  // Combine the arrays based on hideUnavailableItems flag
  const sortedItems = hideUnavailableItems
    ? [...itemsInCart, ...availableItemsNotInCart]
    : [...itemsInCart, ...availableItemsNotInCart, ...unavailableItemsNotInCart];

  return sortedItems;
};

/**
 * Filters out unavailable food items from a shef segment based on the delivery date and cart items.
 *
 * @param {Object} shefSegment - The shef segment containing food items and availability information.
 * @param {string} deliveryDate - The delivery date to check availability against.
 * @param {Array} cartForShef - The cart items for the shef.
 * @returns {Object} - The shef segment with only available food items.
 */
export const removeUnavailableFoodItemsForShefSegment = (
  shefSegment: MealPlanShefSegment,
  deliveryDate,
  cartForShef: MealPlanCartItem[],
  mealPlanNumServings: number
): MealPlanShefSegment => {
  const { foodItems, shefRemainingAvailability } = shefSegment;

  const availableFoodItems = sortFoodItemsByAvailability({
    foodItems,
    deliveryDate,
    shefRemainingAvailability,
    mealPlanNumServings,
    cartForShef,
    hideUnavailableItems: true,
  }).map(({ foodItem }) => foodItem);

  return {
    ...shefSegment,
    foodItems: availableFoodItems,
  };
};

interface FilterShefSegmentsByCuisineParams {
  shefSegments: MealPlanShefSegment[];
  cuisineFilters: string[];
  deliveryDate: string;
  mealPlanNumServings: number;
  shefIdsInCart: Set<string>;
  shefIdsToSkip?: string[];
  initialPageLoadShefIdsInCart?: Set<string>;
  preEditCart?: Cart;
}
/**
 * Returns a filtered list of shefSegments with food items that match the provided cuisineFilters.
 *
 * @param shefSegments - the shefSegments to filter
 * @param cuisineFilters - food items within each shef segment are filtered by these cuisines. Shef
 * segments that have empty food item list will be removed.
 * @param deliveryDate - used to calculate the available food items for filtering
 * @param servingsPerMeal - used in filtering food items for which there is not enough availability
 * @param shefIdsToSkip - optional list of shefIds to filter from results
 * @returns
 */
export const filterShefSegmentsByCuisine = ({
  shefSegments,
  cuisineFilters,
  deliveryDate,
  mealPlanNumServings,
  shefIdsToSkip = [],
  shefIdsInCart,
  initialPageLoadShefIdsInCart = new Set(),
  preEditCart = {},
}: FilterShefSegmentsByCuisineParams): MealPlanShefSegment[] => {
  const noCuisineFilter = cuisineFilters.length === 0;

  const allShefWithFilteredDishes = noCuisineFilter
    ? shefSegments
    : shefSegments.map((shefSegment) => {
        const { foodItems } = shefSegment;
        const dishesForCuisine = foodItems.filter((foodItem) => {
          const foodItemCuisines = foodItem.cuisineCategories;
          return foodItemCuisines.some((cuisine) => cuisineFilters.includes(cuisine.id));
        });
        return { ...shefSegment, foodItems: dishesForCuisine };
      });

  const shefsWithAvailableCuisineDishes = allShefWithFilteredDishes.filter((shefSegment) => {
    const { foodItems, shefRemainingAvailability, shef } = shefSegment;
    const preEditCartForShef = preEditCart[shef.id]?.cartItems;

    const foodItemHasAvailableUnits = (foodItem: MealPlanFoodItem) => {
      const { unitsAvailableBasedOnItem, unitsAvailableBasedOnShef } = calculateShefAndFoodItemAvailabilityInUnits({
        foodItem,
        deliveryDate,
        shefRemainingAvailability,
        mealPlanNumServings,
        preEditCartForShef,
      });
      return (
        shefIdsInCart.has(shefSegment.shef.id) || Math.min(unitsAvailableBasedOnItem, unitsAvailableBasedOnShef) > 0
      );
    };

    const availableItems = foodItems.filter(foodItemHasAvailableUnits);

    return !shefIdsToSkip.includes(shef.id) && availableItems.length > 0;
  });

  const [shefSegmentsInInitialCart, shefSegmentsNotInInitialCart] = partition(
    shefsWithAvailableCuisineDishes,
    ({ shef }) => initialPageLoadShefIdsInCart.has(shef.id)
  );

  return [...shefSegmentsInInitialCart, ...shefSegmentsNotInInitialCart];
};

interface FilterShefRankGroupsByCuisineParams {
  rankGroups: ShefSegmentRankGroups;
  cuisineFilters: string[];
  deliveryDate: string;
  mealPlanNumServings: number;
  shefIdsToSkip?: string[];
  shefIdsInCart?: Set<string>;
  initialPageLoadShefIdsInCart?: Set<string>;
  preEditCart?: Cart;
}
/**
 * Returns each rank group filtered to contain only shef segments that have dishes that satisfy the cuisine filters
 * and availability for the delivery date. If no dishes of that cuisine are available, the shef is not returned.
 * Delegates to `filterShefSegmentsByCuisine` for each rank group.
 */
export const filterShefRankGroupsByCuisine = ({
  rankGroups,
  cuisineFilters,
  deliveryDate,
  mealPlanNumServings,
  shefIdsToSkip = [],
  shefIdsInCart = new Set(),
  initialPageLoadShefIdsInCart = new Set(),
  preEditCart = {},
}: FilterShefRankGroupsByCuisineParams): ShefSegmentRankGroups =>
  rankGroups.map((shefSegments) =>
    filterShefSegmentsByCuisine({
      shefSegments,
      cuisineFilters,
      deliveryDate,
      mealPlanNumServings,
      shefIdsToSkip,
      shefIdsInCart,
      preEditCart,
      initialPageLoadShefIdsInCart,
    })
  );

interface CuisineCategory {
  cuisineFilter: string;
  rootCuisineFilter: string;
  title: string;
  isSelected: boolean;
}

export const getCuisinesForUser = (
  regionId: number,
  cuisinesFromDishes: string[],
  cuisinePreferences?: Maybe<string[]>
): {
  allCuisines: CuisineCategory[];
  userCuisines: CuisineCategory[];
  otherCuisines: string[];
  indianCuisinesSelected: CuisineCategory[];
} => {
  const cuisineMap = CUISINE_MAPPING[regionId];
  const { indianmp = [], ...subCuisineMapping } = cuisineMap ?? {};
  const allIndianCuisines = flattenDeep(Object.entries(subCuisineMapping));

  const allCuisines = [...indianmp, ...allIndianCuisines];

  const filteredBaseCuisines = (indianmp ?? []).reduce(
    ({ userCuisines, otherCuisines }, cur: string) => {
      if (!cuisinesFromDishes.includes(cur)) {
        return { userCuisines, otherCuisines };
      }

      const cuisine = ALL_CUISINES.find(({ cuisineFilter }) => cuisineFilter === cur);
      return (cuisinePreferences ?? []).includes(cur)
        ? { userCuisines: [...userCuisines, cuisine], otherCuisines }
        : { userCuisines, otherCuisines: [...otherCuisines, cuisine] };
    },
    { userCuisines: [], otherCuisines: [] }
  );

  const filteredAllCuisines = (allCuisines ?? []).reduce(
    ({ userCuisines, otherCuisines }, cur: string) => {
      if (!cuisinesFromDishes.includes(cur)) {
        return { userCuisines, otherCuisines };
      }

      const cuisine = ALL_CUISINES.find(({ cuisineFilter }) => cuisineFilter === cur);
      return (cuisinePreferences ?? []).includes(cur)
        ? { userCuisines: [...userCuisines, cuisine], otherCuisines }
        : { userCuisines, otherCuisines: [...otherCuisines, cuisine] };
    },
    { userCuisines: [], otherCuisines: [] }
  );

  // Determine which Indian cuisines are selected, we don't want to include subcuisines in the count
  const indianCuisines = uniq(
    Array.from(ALL_CUISINES).filter((cuisine) => cuisine.rootCuisineFilter.includes('INDIAN'))
  );
  const indianCuisinesSelected = intersection(filteredAllCuisines.userCuisines, indianCuisines);
  const indianCuisineFilters = new Set(indianCuisinesSelected.map((cuisine) => cuisine.cuisineFilter));
  const indianRootCuisines = indianCuisinesSelected.filter(
    (cuisine) => !indianCuisineFilters.has(cuisine.rootCuisineFilter) || cuisine.cuisineFilter === 'INDIAN'
  );
  const numIndianCuisinesSelected = indianRootCuisines.length;
  let userCuisines = [MY_MENU_FILTER, ...filteredAllCuisines.userCuisines];
  if (numIndianCuisinesSelected >= 1) {
    userCuisines = userCuisines.filter((cuisine) => !indianCuisinesSelected.includes(cuisine));
    if (!userCuisines.find((cuisine) => cuisine.cuisineFilter === 'INDIAN')) {
      userCuisines.push(ALL_CUISINES.find((cuisine) => cuisine.cuisineFilter === 'INDIAN'));
    }
  }

  return {
    allCuisines: [MY_MENU_FILTER, ...filteredBaseCuisines.userCuisines, ...filteredBaseCuisines.otherCuisines],
    userCuisines: uniq(userCuisines),
    otherCuisines: filteredBaseCuisines.otherCuisines.map((c) => c.cuisineFilter),
    indianCuisinesSelected: indianRootCuisines,
  };
};

/**
 * Partitions side segments into side segments that have the same shefs as the promoted shefs and side segments that
 * have different shefs. The side segment with different shefs is ranked by the number of matching cuisine categories
 * with the promoted shefs. We also remove unavailable food items from the side segments here, and if numSidesToShow
 * is provided, we crop the side segments so only that many are shown.
 *
 * @param shefIdsToPromote - The shefs to promote to the top of the side segments.
 * @param filteredSideShefSegments - The list of side shef segments to partition.
 * @param nextDeliveryDate - The next delivery date for the meal plan.
 * @param cart - The cart.
 * @param cuisineCategoriesForPromotedShefs - The cuisine categories of the promoted shefs.
 * @param numSidesToShow - The number of sides to show.
 */
export const rankAndPartitionAvailableSideSegments = (
  shefIdsToPromote: Set<string>,
  filteredSideShefSegments: MealPlanShefSegment[],
  nextDeliveryDate: string,
  cart: Record<string, any>,
  cuisineCategoriesForPromotedShefs: Set<string>,
  numSidesToShow?: number
): {
  promotedShefSegmentsWithAvailableSides: MealPlanShefSegment[];
  cuisineMatchingShefSegmentsWithAvailableSides: MealPlanShefSegment[];
  otherShefSegmentsWithAvailableSides: MealPlanShefSegment[];
} => {
  const [promotedShefSegments, nonPromotedShefSegments] = partition(filteredSideShefSegments, (shefSegment) =>
    shefIdsToPromote.has(shefSegment.shef.id)
  );

  promotedShefSegments.sort((a, b) => {
    const indexA = Array.from(shefIdsToPromote).indexOf(a.shef.id);
    const indexB = Array.from(shefIdsToPromote).indexOf(b.shef.id);
    return indexA - indexB; // Sort by the order in shefIdsToPromote
  });

  const [shefSegmentsForCuisineMatchingShefs, otherShefSegments] = partition(nonPromotedShefSegments, (shefSegment) =>
    shefSegment.shef.shefProfile?.cuisineCategories?.some((category) =>
      cuisineCategoriesForPromotedShefs.has(category.id)
    )
  );

  shefSegmentsForCuisineMatchingShefs.sort((a, b) => {
    // Get the matching cuisine categories for both shefs
    const aCuisineCategoriesMatchingPromotedMains =
      a.shef.shefProfile?.cuisineCategories?.filter((category) => cuisineCategoriesForPromotedShefs.has(category.id)) ||
      [];
    const bCuisineCategoriesMatchingPromotedMains =
      b.shef.shefProfile?.cuisineCategories?.filter((category) => cuisineCategoriesForPromotedShefs.has(category.id)) ||
      [];

    // Sort by the number of matching cuisine categories first
    const aMatchCount = aCuisineCategoriesMatchingPromotedMains.length;
    const bMatchCount = bCuisineCategoriesMatchingPromotedMains.length;

    // If one shef has more matching cuisine categories, it comes first
    if (aMatchCount > bMatchCount) return -1; // a comes first
    if (aMatchCount < bMatchCount) return 1; // b comes first

    return 0;
  });

  let remainingSidesToShow = numSidesToShow ?? null;

  // Returns the available shef segments and crops the sides if necessary
  const getCroppedAvailableShefSegments = (
    shefSegments: MealPlanShefSegment[],
    nextDeliveryDate: string,
    cart: Record<string, any>,
    remainingSidesToShow: number | null
  ): {
    availableShefSegments: MealPlanShefSegment[];
    updatedRemainingSidesToShow: number | null;
  } => {
    const availableShefSegments: MealPlanShefSegment[] = [];

    shefSegments.some((shefSegment) => {
      const availableShefSegment = removeUnavailableFoodItemsForShefSegment(
        shefSegment,
        nextDeliveryDate,
        cart[shefSegment.shef.id]?.cartItems,
        1
      );
      const availableDishesCount = availableShefSegment.foodItems?.length ?? 0;

      if (remainingSidesToShow !== null && remainingSidesToShow <= 0) {
        return true;
      }

      if (remainingSidesToShow === null) {
        availableShefSegments.push(availableShefSegment);
      } else if (availableDishesCount <= remainingSidesToShow) {
        availableShefSegments.push(availableShefSegment);
        remainingSidesToShow -= availableDishesCount;
      } else {
        availableShefSegment.foodItems = availableShefSegment.foodItems.slice(0, remainingSidesToShow);
        availableShefSegments.push(availableShefSegment);
        remainingSidesToShow = 0;
      }
      return false;
    });

    return { availableShefSegments, updatedRemainingSidesToShow: remainingSidesToShow };
  };

  // If numSidesInPromotedShefs > numSidesToShow, we need to crop the sides in this segment
  const { availableShefSegments: promotedShefSegmentsWithAvailableSides, updatedRemainingSidesToShow } =
    getCroppedAvailableShefSegments(promotedShefSegments, nextDeliveryDate, cart, remainingSidesToShow);
  remainingSidesToShow = updatedRemainingSidesToShow;

  const {
    availableShefSegments: cuisineMatchingShefSegmentsWithAvailableSides,
    updatedRemainingSidesToShow: updatedRemainingSidesToShowForCuisineMatchingShefs,
  } = getCroppedAvailableShefSegments(
    shefSegmentsForCuisineMatchingShefs,
    nextDeliveryDate,
    cart,
    remainingSidesToShow
  );
  remainingSidesToShow = updatedRemainingSidesToShowForCuisineMatchingShefs;

  const { availableShefSegments: otherShefSegmentsWithAvailableSides } = getCroppedAvailableShefSegments(
    otherShefSegments,
    nextDeliveryDate,
    cart,
    remainingSidesToShow
  );

  return {
    promotedShefSegmentsWithAvailableSides,
    cuisineMatchingShefSegmentsWithAvailableSides,
    otherShefSegmentsWithAvailableSides,
  };
};
