import cn from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import { Icon, IconName } from '../Icon';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  TextButton = 'textButton',
  TextLink = 'textLink',
}

interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  align?: 'center' | 'edges';
  buttonType?: ButtonType;
  darkBackground?: boolean;
  iconName?: IconName;
  iconPosition?: 'left' | 'right';
  isLoading?: boolean;
  renderFullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  align,
  buttonType = ButtonType.Primary,
  children,
  darkBackground,
  disabled = false,
  iconName,
  iconPosition = 'right',
  id,
  isLoading = false,
  renderFullWidth = false,
  ...props
}) => {
  const isDisabled = disabled || isLoading;
  const isTextButton = [ButtonType.TextButton, ButtonType.TextLink].includes(buttonType);
  const isTertiaryButton = buttonType === ButtonType.Tertiary;
  return (
    <button
      {...props}
      id={id}
      disabled={isDisabled}
      className={cn('box-border cursor-pointer gap-1 text-caption-600 outline-none', {
        /* Common Button Styles for all types except TextLink */
        'box-border flex h-[45px] w-full items-center rounded-xl': !isTextButton,
        'px-6': !isTextButton && !isTertiaryButton,
        'px-2 md:px-6': isTertiaryButton,

        /* Primary Button Styles */
        [`border-0 bg-raspberry text-milk 
        hover:bg-raspberry-hover 
        focus-visible:border-3 focus-visible:border-solid focus-visible:border-raspberry-active 
        active:bg-raspberry-active
        `]: buttonType === ButtonType.Primary && !isDisabled,

        /* Primary Button Disabled Styles */
        'bg-eggplant-100 text-white': Boolean(isDisabled && buttonType === ButtonType.Primary),

        /* Secondary Button Styles */
        [`border-3 border-eggplant-50 bg-milk text-eggplant-500
        hover:border-eggplant-200 
        focus-visible:border-eggplant-500 
        active:border-eggplant-500 active:bg-eggplant-50`]: buttonType === ButtonType.Secondary && !isDisabled,

        /* Secondary Button Disabled Styles */
        'border-3 border-eggplant-50 bg-milk text-eggplant-200': buttonType === ButtonType.Secondary && isDisabled,

        /* Tertiary Button Styles */
        [`border-3 border-solid border-eggplant-50 bg-rice
        text-eggplant-500 
        hover:border-eggplant-200 
        focus-visible:border-eggplant-500 active:border-eggplant-300`]: isTertiaryButton && !isDisabled,

        /* Tertiary Button Disabled Styles */
        'border-3 border-eggplant-50 bg-rice text-eggplant-200': isTertiaryButton && isDisabled,

        /* Text Link Common Styles */
        'inline text-nowrap border-none': isTextButton,

        /* Text Link Standard Styles */
        [`text-eggplant-500 
        hover:text-raspberry 
        focus-visible:text-caption-700 focus-visible:text-raspberry-active
        active:text-raspberry-active`]: isTextButton && !darkBackground && !isDisabled,

        /* Text Link Standard Disabled Styles */
        'text-eggplant-200': isTextButton && !darkBackground && isDisabled,

        /* Text Link Dark Background Styles  */
        [`text-rice 
        hover:text-raspberry 
        focus-visible:text-caption-700 focus-visible:text-raspberry-active 
        active:text-raspberry-active`]: isTextButton && darkBackground && !isDisabled,

        /* Text Link Dark Background Disabled Styles  */
        'text-eggplant-300': isTextButton && darkBackground && isDisabled,

        /* Other styles */
        'max-w-[400px]': !renderFullWidth && !isTextButton,
      })}>
      <span
        id={id}
        className={cn('flex w-full items-center gap-1', {
          /* Icon Position Styles */
          'flex-row-reverse': iconPosition === 'left',
          'justify-between': align === 'edges',
          'justify-center': align !== 'edges',
        })}>
        <span id={id} className={cn({ underline: buttonType === ButtonType.TextLink })}>
          {children}
        </span>

        {isLoading ? (
          <i className='fa-solid fa-circle-notch fa-spin' id={id} />
        ) : (
          iconName && !isLoading && <Icon name={iconName} size={16} id={id} />
        )}
      </span>
    </button>
  );
};

export default Button;
