import cn from 'classnames';
import { Upsell } from 'src/gqlReactTypings.generated.d';
import { generateThumborImgUrl } from 'src/shared/design-system/DynamicImage';
import { UpsellLabelChip } from '../components/UpsellChips';
import { FoodItemChip } from './FoodItemChip';

interface MealPlanDishImageProps {
  imageUrl: string;
  height: number;
  width: number;
  remainingAvailableUnits: number;
  unitsInCart: number;
  upsell?: Upsell | null;
  className?: string;
  aspectRatio?: '3/2';
}

export const MealPlanDishImage: React.FC<MealPlanDishImageProps> = ({
  className,
  imageUrl,
  remainingAvailableUnits,
  unitsInCart,
  height,
  width,
  upsell,
  aspectRatio,
}) => {
  const isInCart = unitsInCart > 0;
  const disabled = !isInCart && remainingAvailableUnits <= 0;
  const renderGreyOverlay = <div className='absolute left-0 top-0 size-full bg-white opacity-60' />;

  const upsellChip = (
    <div className='absolute bottom-4 left-2'>
      <UpsellLabelChip upsell={upsell} />
    </div>
  );
  const foodItemChip = (
    <div className='absolute left-2 top-2'>
      <FoodItemChip remainingAvailableUnits={remainingAvailableUnits} unitsInCart={unitsInCart} />
    </div>
  );

  const imageSrc = generateThumborImgUrl({ src: imageUrl, width, height });

  return (
    <div className={cn('relative w-full', className)} style={{ aspectRatio }}>
      {disabled && renderGreyOverlay}
      <img className='size-full shrink-0 object-cover object-center' style={{ aspectRatio }} src={imageSrc} alt='' />
      <>
        {foodItemChip}
        {upsell && upsellChip}
      </>
    </div>
  );
};
