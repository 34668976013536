import { match } from 'ts-pattern';
import {
  FLEXIBLE_SIDES_DEFAULT_SIDE_PRICE,
  FLEXIBLE_SIDES_DEFAULT_STAPLE_PRICE,
  FLEXIBLE_SIDES_MP_MAIN_FOOD_ITEM_IDS,
  FLEXIBLE_SIDES_MP_SIDE_FOOD_ITEM_IDS,
  FLEXIBLE_SIDES_MP_STAPLE_FOOD_ITEM_IDS,
  MAINS_PLUS_SIDES_MP_MAIN_FOOD_ITEM_IDS,
  MAINS_PLUS_SIDES_MP_SIDE_FOOD_ITEM_IDS,
  MEAL_PLAN_DEFAULT_SIDE_PRICE,
} from './Constants';

/**
 * Cutoff time for making adjustments to deliveries is 3pm 3 days prior to nextDeliveryDate
 * If the user visits the site after 3pm, we consider `currentDate` as the next day
 * To ensure that we don't run into issues with date comparisons, we set current time to 2pm
 * and set cutoffDate to 3pm to make sure we don't run into any edge cases
 */
export const getCutoffDate = (
  nextDeliveryDateString: string,
  subtractAdditionalHours = 0
): { isPastCutoffDate: boolean; cutoffDate: Date } => {
  const currentDate = new Date();
  const isAfter3pm = currentDate.getHours() >= 15;
  if (isAfter3pm) {
    // If it's after 3pm, set the date to the next day
    currentDate.setDate(currentDate.getDate() + 1);
    // set the hours to 2pm for the next day to compare to cutoff date
    currentDate.setHours(14, 0, 0, 0);
  }
  const nextDeliveryDate = new Date(nextDeliveryDateString);
  const cutoffDate = new Date(nextDeliveryDate);
  cutoffDate.setDate(nextDeliveryDate.getDate() - 3);
  // set hours to 3pm for comparison to currentDate
  cutoffDate.setHours(15 - subtractAdditionalHours);
  cutoffDate.setMinutes(0);
  cutoffDate.setSeconds(0);

  return {
    isPastCutoffDate: currentDate > cutoffDate,
    cutoffDate,
  };
};

export const isMealPlanSideDish = (foodItemId: number): boolean =>
  MAINS_PLUS_SIDES_MP_SIDE_FOOD_ITEM_IDS.has(foodItemId) ||
  FLEXIBLE_SIDES_MP_SIDE_FOOD_ITEM_IDS.has(foodItemId) ||
  FLEXIBLE_SIDES_MP_STAPLE_FOOD_ITEM_IDS.has(foodItemId);

export const getServingsPerMealForItem = (foodItemId: number, numServings: number): number => {
  const isSideDish = isMealPlanSideDish(foodItemId);
  return isSideDish ? 1 : numServings;
};
export const isMealPlanMainDish = (foodItemId: number): boolean =>
  MAINS_PLUS_SIDES_MP_MAIN_FOOD_ITEM_IDS.has(foodItemId) || FLEXIBLE_SIDES_MP_MAIN_FOOD_ITEM_IDS.has(foodItemId);

export const getBasePriceForSideOrStaple = (foodItemId: number, isFlexibleSides: boolean): number => {
  const isMPSSideItem = MAINS_PLUS_SIDES_MP_SIDE_FOOD_ITEM_IDS.has(foodItemId);
  const isFSStapleItem = FLEXIBLE_SIDES_MP_STAPLE_FOOD_ITEM_IDS.has(foodItemId);
  const isFSSideItem = FLEXIBLE_SIDES_MP_SIDE_FOOD_ITEM_IDS.has(foodItemId);

  return match({ isMPSSideItem, isFSStapleItem, isFSSideItem, isFlexibleSides })
    .with({ isMPSSideItem: true, isFlexibleSides: false }, () => MEAL_PLAN_DEFAULT_SIDE_PRICE)
    .with({ isFSStapleItem: true, isFlexibleSides: true }, () => FLEXIBLE_SIDES_DEFAULT_STAPLE_PRICE)
    .with({ isFSSideItem: true, isFlexibleSides: true }, () => FLEXIBLE_SIDES_DEFAULT_SIDE_PRICE)
    .with({ isMPSSideItem: true, isFlexibleSides: true }, () => FLEXIBLE_SIDES_DEFAULT_SIDE_PRICE)
    .otherwise(() => 0);
};
