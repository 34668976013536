import { OAuthLoginType, OAuthMissingFieldsResult } from 'src/gqlReactTypings.generated.d';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export enum FormType {
  ENTRY_POINT,
  REGISTER_WITH_EMAIL,
  LOGIN_WITH_EMAIL,
  OAUTH_MISSING_FIELDS,
}

type OAuthMissingFieldsState = {
  formType: FormType.OAUTH_MISSING_FIELDS;
  oAuthType: OAuthLoginType;
  token: string;
  missingFields: OAuthMissingFieldsResult;
};

export type EmailFormState = {
  formType: FormType.ENTRY_POINT | FormType.LOGIN_WITH_EMAIL | FormType.REGISTER_WITH_EMAIL;
  email: string;
  errorMessage?: string;
};

export type LoginOrSignUpModalState = OAuthMissingFieldsState | EmailFormState;

export enum ModalActionTypes {
  SET_EMAIL,
  CONTINUE_WITH_EMAIL,
  SWITCH_TO_MISSING_FIELDS_STATE,
  SWITCH_TO_ENTRY,
}

export type ModalAction =
  | {
      type: ModalActionTypes.SET_EMAIL;
      email: string;
    }
  | {
      type: ModalActionTypes.CONTINUE_WITH_EMAIL;
      email: string;
      errorMessage?: string;
      isExistingUser: boolean;
    }
  | {
      type: ModalActionTypes.SWITCH_TO_MISSING_FIELDS_STATE;
      oAuthType: OAuthLoginType;
      token: string;
      missingFields: OAuthMissingFieldsResult;
    }
  | {
      type: ModalActionTypes.SWITCH_TO_ENTRY;
    };

interface ModalActions {
  dispatchModalAction: (action: ModalAction) => void;
}

interface State {
  modalState: LoginOrSignUpModalState;
}

const INITIAL_STATE: State = {
  modalState: {
    formType: FormType.ENTRY_POINT,
    email: '',
    errorMessage: undefined,
  },
};

const isEmailState = (state: LoginOrSignUpModalState): state is EmailFormState =>
  [FormType.ENTRY_POINT, FormType.LOGIN_WITH_EMAIL, FormType.REGISTER_WITH_EMAIL].includes(state.formType);

export const useLoginOrSignUpModalStore = create(
  immer<State & ModalActions>((set, get) => ({
    ...INITIAL_STATE,
    dispatchModalAction: (action: ModalAction) => {
      const state = get();

      const handleModalAction = (action: ModalAction): LoginOrSignUpModalState => {
        switch (action.type) {
          case ModalActionTypes.SET_EMAIL: {
            if (isEmailState(state.modalState)) {
              return { ...state.modalState, email: action.email };
            }
            return state.modalState;
          }
          case ModalActionTypes.CONTINUE_WITH_EMAIL: {
            const formType = action.isExistingUser ? FormType.LOGIN_WITH_EMAIL : FormType.REGISTER_WITH_EMAIL;
            return { formType, email: action.email, errorMessage: action.errorMessage };
          }
          case ModalActionTypes.SWITCH_TO_MISSING_FIELDS_STATE: {
            const { oAuthType, token, missingFields } = action;
            return { formType: FormType.OAUTH_MISSING_FIELDS, oAuthType, token, missingFields };
          }
          case ModalActionTypes.SWITCH_TO_ENTRY: {
            const email = isEmailState(state.modalState) ? state.modalState.email : '';
            return { formType: FormType.ENTRY_POINT, email };
          }
          default:
            return state.modalState;
        }
      };

      const newState = handleModalAction(action);
      set({ modalState: newState });
    },
  }))
);
