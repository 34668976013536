import cn from 'classnames';
import { MealPlanAnalyticsLocation } from 'common/events/ClientEvents';
import { getBasePriceForSideOrStaple, getServingsPerMealForItem } from 'common/MealPlanUtils';
import { isNil } from 'lodash';
import { MealPlanFormat } from 'src/gqlReactTypings.generated.d';
import { MealPlanQuantityPicker } from 'src/pages/consumer/meal-plans/checkout/MealPlanQuantityPicker';
import { generateThumborImgUrl } from 'src/shared/design-system/DynamicImage/utils';
import { useCartStoreSelectors } from 'src/ztore/cart-store';
import { Icon, IconColor } from 'web-common/src/shared/design-system/components/Icon';
import { FoodItemRatings } from '../components/FoodItemRatings';
import { FoodItemChip } from './FoodItemChip';
import { PriceChip, ServingChip } from './MealPlanDishCard';
import { DishCardDisplayMode, MealPlanFoodItem, MealPlanShefSegment } from './types';

interface IProps {
  displayMode: DishCardDisplayMode;
  buttonsDisabled: boolean;
  foodItem: MealPlanFoodItem;
  foodItemIndex: number;
  remainingAvailableUnits: number;
  mealPlanNumServings: number;
  shefIndex?: number;
  shefSegment: MealPlanShefSegment;
  showShef?: boolean;
  openDishModal: () => void;
  format: MealPlanFormat;
  analyticsLocation: MealPlanAnalyticsLocation;
}

export const MealPlanDishCardSmall: React.FC<IProps> = ({
  buttonsDisabled,
  displayMode = DishCardDisplayMode.Carousel,
  foodItem,
  foodItemIndex,
  remainingAvailableUnits,
  mealPlanNumServings,
  shefIndex,
  shefSegment,
  format,
  showShef = false,
  openDishModal,
  analyticsLocation,
}) => {
  const { cart } = useCartStoreSelectors();
  const { shef } = foodItem;

  const unitsInCart = cart[shef.id]?.cartItems.find((item) => item.foodItem.id === foodItem.id)?.quantity ?? 0;
  const servingsPerMeal = getServingsPerMealForItem(foodItem.id, mealPlanNumServings);
  const foodItemChip = (
    <div className='absolute left-2 top-2'>
      <FoodItemChip remainingAvailableUnits={remainingAvailableUnits} unitsInCart={unitsInCart} />
    </div>
  );

  const imageSrc = generateThumborImgUrl({ src: foodItem.imageUrl ?? '', width: 149, height: 154 });

  const dishImage = (
    <div
      className={cn('flex h-full w-[138px] items-end bg-cover bg-center p-2')}
      style={{ backgroundImage: `url(${imageSrc})` }}>
      <>{foodItemChip}</>
    </div>
  );

  return (
    <div
      onClick={openDishModal}
      className={cn(
        'box-border flex h-[148px] cursor-pointer gap-4',
        'overflow-hidden rounded-lg border border-neutral-100 bg-white pr-2 shadow-lg',
        {
          'w-[330px] shrink-0': displayMode === DishCardDisplayMode.Carousel,
          'w-full': displayMode === DishCardDisplayMode.Grid,
        }
      )}>
      <div className='relative'>{dishImage}</div>

      <div className='flex w-full flex-col justify-between self-stretch py-3'>
        <section>
          <p className='m-0 line-clamp-2 text-ellipsis text-h6-bold text-eggplant'>{foodItem.name}</p>
        </section>
        <section className='flex w-full flex-col gap-1'>
          {showShef && (
            <p className='m-0 flex items-center gap-1 text-label-700'>
              <span>Shef</span>
              <span className='line-clamp-1 font-quick-writers text-h6 text-raspberry'>{shef.publicName}</span>
            </p>
          )}
          <div className='flex items-center'>
            <Icon name='thumbsUp' size={16} color={IconColor.EGGPLANT_400} />
            <FoodItemRatings
              ratingCount={foodItem.ratingCount}
              averageRating={foodItem.averageRating}
              foodItemId={foodItem.id}
            />
          </div>
          <hr className='mb-0.5' />
          <div className='flex w-full flex-row justify-between pr-1'>
            <div className='flex gap-1'>
              <ServingChip numServings={servingsPerMeal} />
              <PriceChip
                price={
                  getBasePriceForSideOrStaple(foodItem.id, format === MealPlanFormat.FlexibleSides) +
                  (foodItem.upsell?.price ?? 0)
                }
              />
            </div>
            <MealPlanQuantityPicker
              outline={false}
              foodItem={foodItem}
              value={unitsInCart}
              disabled={buttonsDisabled}
              shefSegment={shefSegment}
              numServings={servingsPerMeal}
              shefPosition={isNil(shefIndex) ? undefined : shefIndex + 1}
              foodItemPosition={foodItemIndex + 1}
              analyticsLocation={analyticsLocation}
            />
          </div>
        </section>
      </div>
    </div>
  );
};
