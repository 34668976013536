import cn from 'classnames';
import { MealPlanAnalyticsLocation } from 'common/events/ClientEvents';
import { getServingsPerMealForItem } from 'common/MealPlanUtils';
import { noop } from 'lodash';
import React from 'react';
import { MealPlanDishCardFoodItemFragment } from 'src/gqlReactTypings.generated.d';
import { MinusV2, PlusV2 } from 'src/shared/design-system/Icon';
import { useCartStoreSelectors } from 'src/ztore/cart-store';
import { useSegmentStoreSelectors } from 'src/ztore/segment-store';
import { useMealPlanTrackingCartChange } from '../hooks/useMealPlanTrackingCartChange';
import { MealPlanFoodItem, MealPlanShefSegment } from './types';

interface MealPlanQuantityPickerProps {
  value: number;
  shefSegment?: MealPlanShefSegment;
  foodItem: MealPlanFoodItem | MealPlanDishCardFoodItemFragment;
  numServings?: number;
  disabled?: boolean;
  shefPosition?: number;
  foodItemPosition?: number;
  editingDisabled?: boolean;
  outline?: boolean;
  onIncrement?: (foodItem) => void;
  analyticsLocation: MealPlanAnalyticsLocation | null;
}

const largerTapTargetClasses =
  'before:content-[""] before:absolute before:w-[32px] before:h-[32px] before:cursor-pointer before:left-[-8px] relative';
export const MealPlanQuantityPicker: React.FC<MealPlanQuantityPickerProps> = ({
  value = 0,
  foodItem,
  disabled = false,
  numServings,
  shefSegment,
  shefPosition,
  foodItemPosition,
  editingDisabled = false,
  outline = true,
  onIncrement,
  analyticsLocation,
}) => {
  const { increaseCartQuantity, decreaseCartQuantity } = useCartStoreSelectors();
  const { foodItemIdAvailability, setFoodItemIdAvailability, getShefRemainingAvailability, addShefAvailability } =
    useSegmentStoreSelectors();
  const trackCartChange = useMealPlanTrackingCartChange();

  const incrementInCart = (foodItem, shefId, shefPosition, foodItemPosition, shefSegment) => {
    if (editingDisabled) {
      return;
    }
    // Cart manipulation
    const cartItem = {
      foodItem,
      quantity: 1,
    };
    increaseCartQuantity(cartItem, shefSegment);
    setFoodItemIdAvailability({
      [foodItem.id]: foodItemIdAvailability[foodItem.id] - 1,
    });
    const shefAvailabilityToSubtract = getServingsPerMealForItem(foodItem.id, numServings ?? 0);
    addShefAvailability((getShefRemainingAvailability(shefId) ?? 0) - shefAvailabilityToSubtract, shefId);
    // Analytics
    if (analyticsLocation) {
      trackCartChange({
        foodItem,
        shefId,
        shefPosition,
        foodItemPosition,
        analyticsLocation,
        action: 'add',
      });
    }
  };

  const increment = (foodItem, shefId, shefPosition, foodItemPosition, shefSegment) => {
    if (editingDisabled) return noop;

    if (onIncrement) {
      onIncrement(foodItem);
    }

    return incrementInCart(foodItem, shefId, shefPosition, foodItemPosition, shefSegment);
  };

  const decrementInCart = (foodItem, shefId, shefPosition, foodItemPosition) => {
    if (editingDisabled) {
      return;
    }
    // Cart manipulation
    const cartItem = {
      foodItem,
      quantity: 1,
    };
    decreaseCartQuantity(cartItem);
    setFoodItemIdAvailability({
      [foodItem.id]: foodItemIdAvailability[foodItem.id] + 1,
    });
    const shefAvailabilityToAdd = getServingsPerMealForItem(foodItem.id, numServings ?? 0);
    addShefAvailability((getShefRemainingAvailability(shefId) ?? 0) + shefAvailabilityToAdd, shefId);

    // Analytics
    if (analyticsLocation) {
      trackCartChange({
        foodItem,
        shefId,
        shefPosition,
        foodItemPosition,
        analyticsLocation,
        action: 'remove',
      });
    }
  };

  const decrement = editingDisabled ? noop : decrementInCart;

  return (
    <div
      className={cn(`flex h-7 flex-row items-center justify-between rounded-lg border border-solid`, {
        'border-none': !outline,
        'gap-1 border-neutral-100 bg-raspberry px-2 text-white': value >= 1 && !editingDisabled,
        'border-white': value < 1 && !editingDisabled,
        'bg-raspberry': editingDisabled,
        'text-white': editingDisabled,
      })}>
      {value >= 1 && !editingDisabled && (
        <button
          className={cn(
            'm-0 flex items-center border-none p-0 font-bold leading-tight text-white',
            largerTapTargetClasses,
            {
              'bg-white text-raspberry': value < 1,
              'bg-raspberry text-white': value >= 1,
            }
          )}
          onClick={(e) => {
            e.stopPropagation();
            decrement(foodItem, foodItem.shef.id, shefPosition, foodItemPosition);
          }}>
          <MinusV2 />
        </button>
      )}
      {(value >= 1 || editingDisabled) && (
        <span className={cn('mt-1 text-base', { 'px-2': editingDisabled })}>{value}</span>
      )}
      {!editingDisabled && (
        <button
          disabled={disabled}
          className={cn('m-0 flex items-center border-none p-0 font-bold leading-tight', largerTapTargetClasses, {
            ' bg-white text-neutral-200': disabled && value < 1,
            'bg-raspberry text-raspberry-hover': disabled && value >= 1,
            'bg-white text-raspberry': value < 1 && !disabled,
            'bg-raspberry text-white': value >= 1 && !disabled,
          })}
          onClick={(e) => {
            e.stopPropagation();
            increment(foodItem, foodItem.shef.id, shefPosition, foodItemPosition, shefSegment);
          }}>
          <PlusV2 />
        </button>
      )}
    </div>
  );
};
