import cn from 'classnames';
import React from 'react';
import { ReactComponent as EllipsedEdgeLarge } from './assets/ellipsedEdgeLarge.svg';
import { ReactComponent as EllipsedEdgeSmall } from './assets/ellipsedEdgeSmall.svg';

export enum EllipsedEdgeColor {
  SOURDOUGH = 'sourdough',
}

const EdgeColor = {
  [EllipsedEdgeColor.SOURDOUGH]: 'text-sourdough',
};

const BackgroundColor = {
  [EllipsedEdgeColor.SOURDOUGH]: 'bg-sourdough',
};

interface EllipsedEdgeProps {
  color: EllipsedEdgeColor;
  children: React.ReactNode;
  className?: string;
}

export const EllipsedEdgeContainer: React.FC<EllipsedEdgeProps> = ({ children, color, className }) => (
  <div className={cn('relative z-0 -mt-10 w-full overflow-hidden', className)}>
    <EllipsedEdgeLarge className={cn('-ml-[calc(500px-50%)] max-md:hidden lg:hidden', EdgeColor[color])} />
    <EllipsedEdgeSmall className={cn('-ml-[calc(320px-50%)] md:hidden lg:block', EdgeColor[color])} />
    <div className={cn('-mt-2 w-full', BackgroundColor[color])}>{children}</div>
  </div>
);
