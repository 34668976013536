import React from 'react';
import Button, { ButtonType } from 'web-common/src/shared/design-system/components/Button';
import { IconName } from 'web-common/src/shared/design-system/components/Icon';

interface LoginButtonV2Props {
  iconName: IconName;
  onClick: React.MouseEventHandler;
}

export const LoginButtonV2: React.FC<LoginButtonV2Props & React.ComponentPropsWithoutRef<'button'>> = ({
  iconName,
  onClick,
  children,
}) => (
  <Button onClick={onClick} buttonType={ButtonType.Tertiary} iconName={iconName} iconPosition='left'>
    {children}
  </Button>
);
