/* eslint-disable no-param-reassign */
/* eslint-disable functional/immutable-data */
import { isNil } from 'lodash';
import { MealPlanShefSegment } from 'src/pages/consumer/meal-plans/checkout/types';
import { calculateShefAndFoodItemAvailabilityInUnits } from 'src/pages/consumer/meal-plans/checkout/utils';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { Cart } from './cart-store';
import { createSelectors } from './create-selectors';

interface ShefCacheState {
  mains: MealPlanShefSegment | null;
  sides: MealPlanShefSegment | null;
  shefRemainingAvailability: number | null;
}

export interface FoodItemIdAvailability {
  [foodItemId: number]: number;
}

interface State {
  shefSegmentIndex: Record<string, ShefCacheState>;
  foodItemIdAvailability: FoodItemIdAvailability;
  shefSegmentsLoading: boolean;
}

interface Actions {
  addAvailableMainsShefSegments: (mainsShefSegments: MealPlanShefSegment[]) => void;
  addAvailableSidesShefSegments: (sidesShefSegments: MealPlanShefSegment[]) => void;
  addShefAvailability: (shefAvailability: number, shefId: string) => void;
  getMainsShefSegmentForShef: (shefId: string) => MealPlanShefSegment | null;
  getSidesShefSegmentForShef: (shefId: string) => MealPlanShefSegment | null;
  getShefRemainingAvailability: (shefId: string) => number | null;
  setFoodItemIdAvailability: (foodItemIdAvailability: FoodItemIdAvailability) => void;
  getFoodItemIdAvailability: () => FoodItemIdAvailability;
  getAvailabilityForFoodItemId: (foodItemId: number) => number;
  setShefSegmentsLoading: (loading: boolean) => void;
}

// Initialize a default state
export const INITIAL_STATE: State = {
  shefSegmentIndex: {},
  foodItemIdAvailability: {},
  shefSegmentsLoading: false,
};

export const useSegmentStore = create(
  persist(
    immer<State & Actions>((set, get) => ({
      ...INITIAL_STATE,
      addAvailableMainsShefSegments: (mainsShefSegments: MealPlanShefSegment[]): void => {
        const { shefSegmentIndex } = get();
        const updatedShefSegmentIndex = mainsShefSegments.reduce((accum, shefSegment) => {
          const {
            shef: { id: shefId },
            shefRemainingAvailability,
          } = shefSegment;
          if (isNil(accum[shefId])) {
            accum[shefId] = { mains: null, sides: null, shefRemainingAvailability };
          }
          accum[shefId].mains = { ...accum[shefId].mains, ...shefSegment };
          return accum;
        }, shefSegmentIndex);
        set({ shefSegmentIndex: updatedShefSegmentIndex });
      },
      addAvailableSidesShefSegments: (sidesShefSegments: MealPlanShefSegment[]): void => {
        const { shefSegmentIndex } = get();
        const updatedShefSegmentIndex = sidesShefSegments.reduce((accum, shefSegment) => {
          const {
            shef: { id: shefId },
            shefRemainingAvailability,
          } = shefSegment;
          if (isNil(accum[shefId])) {
            accum[shefId] = { mains: null, sides: null, shefRemainingAvailability };
          }
          accum[shefId].sides = { ...accum[shefId].sides, ...shefSegment };
          return accum;
        }, shefSegmentIndex);
        set({ shefSegmentIndex: updatedShefSegmentIndex });
      },
      addShefAvailability: (shefAvailability: number, shefId: string): void => {
        const { shefSegmentIndex } = get();
        if (isNil(shefSegmentIndex[shefId])) {
          shefSegmentIndex[shefId] = { mains: null, sides: null, shefRemainingAvailability: shefAvailability };
        } else {
          shefSegmentIndex[shefId].shefRemainingAvailability = shefAvailability;
        }
      },
      getMainsShefSegmentForShef: (shefId: string): MealPlanShefSegment | null => {
        const { shefSegmentIndex } = get();
        return shefSegmentIndex[shefId]?.mains;
      },
      getSidesShefSegmentForShef: (shefId: string): MealPlanShefSegment | null => {
        const { shefSegmentIndex } = get();
        return shefSegmentIndex[shefId]?.sides;
      },
      getShefRemainingAvailability: (shefId: string): number | null => {
        const { shefSegmentIndex } = get();
        return shefSegmentIndex[shefId]?.shefRemainingAvailability ?? 0;
      },
      setShefSegmentsLoading: (loading: boolean): void => {
        set({ shefSegmentsLoading: loading });
      },
      setFoodItemIdAvailability: (foodItemIdAvailability: FoodItemIdAvailability): void => {
        const { foodItemIdAvailability: oldAvailability } = get();
        const updatedFoodItemIdAvailability = { ...oldAvailability, ...foodItemIdAvailability };
        set({ foodItemIdAvailability: updatedFoodItemIdAvailability });
      },
      getFoodItemIdAvailability: (): FoodItemIdAvailability => {
        const { foodItemIdAvailability } = get();
        return foodItemIdAvailability;
      },
      getAvailabilityForFoodItemId: (foodItemId: number): number => {
        const { foodItemIdAvailability } = get();
        return foodItemIdAvailability[foodItemId];
      },
    })),
    {
      name: 'segment-storage', // unique name
      getStorage: () => localStorage,
    }
  )
);

export const useSegmentStoreSelectors = createSelectors(useSegmentStore);

export const getFoodItemIdAvailability = (
  segments: MealPlanShefSegment[],
  mealPlanNumServings: number,
  deliveryDate: string,
  cart: Cart,
  preEditCart: Cart
): FoodItemIdAvailability =>
  segments.reduce((availabilityAccum, { foodItems, shef, shefRemainingAvailability }) => {
    const { id: shefId } = shef;
    foodItems.forEach((foodItem) => {
      const { id: foodItemId } = foodItem;
      if (isNil(foodItem) || isNil(mealPlanNumServings) || isNil(deliveryDate)) {
        return;
      }
      const { unitsAvailableBasedOnItem: availableUnits } = calculateShefAndFoodItemAvailabilityInUnits({
        foodItem,
        deliveryDate,
        shefRemainingAvailability,
        mealPlanNumServings,
        cartForShef: cart[shefId]?.cartItems,
        preEditCartForShef: preEditCart[shefId]?.cartItems,
      });
      availabilityAccum[foodItemId] = availableUnits;
    });
    return availabilityAccum;
  }, {});

export const isShefSegmentSoldOut = (
  shefSegment: MealPlanShefSegment,
  foodItemIdAvailability: FoodItemIdAvailability,
  cart: Cart,
  preEditCart: Cart
): boolean => {
  const cartItems = cart[shefSegment.shef.id]?.cartItems ?? [];
  const preEditCartItems = preEditCart[shefSegment.shef.id]?.cartItems ?? [];

  if (cartItems.length > 0 || preEditCartItems.length > 0) {
    return false;
  }

  if (shefSegment.shefRemainingAvailability === 0) {
    return true;
  }
  const hasAvailableFoodItem = shefSegment?.foodItems?.some(({ id }) => {
    const foodItemIsAvailable = foodItemIdAvailability[id] > 0;
    return foodItemIsAvailable;
  });
  return !hasAvailableFoodItem;
};
