import { noOp } from 'common/Constants';
import { MealPlanAnalyticsLocation } from 'common/events/ClientEvents';
import { getServingsPerMealForItem } from 'common/MealPlanUtils';
import { isEqual, isNil } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { DishModalFoodItemFragment, MealPlanFormat, Upsell, useDishModalQuery } from 'src/gqlReactTypings.generated.d';
import { CardType } from 'src/pages/consumer/meal-plans/checkout/MealPlanDishCard';
import { MealPlanFoodItem, MealPlanShefSegment } from 'src/pages/consumer/meal-plans/checkout/types';
import { BaseDishModal, IBaseDishModalFormState } from 'src/shared/design-system/DishFirst/DishModal/BaseDishModal';
import { DEFAULT_CUSTOMIZATION_ID } from 'src/shared/design-system/DishFirst/DishModal/types';
import { useCartStoreSelectors } from 'src/ztore/cart-store';
import { useSegmentStoreSelectors } from 'src/ztore/segment-store';
import { useMealPlanTrackingCartChange } from '../hooks/useMealPlanTrackingCartChange';

interface IMealPlanDishModal {
  disabled?: boolean;
  foodItem: MealPlanFoodItem;
  isShowing?: boolean;
  loadedData?: DishModalFoodItemFragment;
  maxQuantity?: number | null;
  shareUrl?: string;
  shefMenuUrl?: string;
  shefSegment: MealPlanShefSegment;
  showAddButton?: boolean;
  showModalPrompt?: boolean;
  onClose?: () => void;
  foodItemIndex: number;
  shefIndex?: number;
  openModal?: () => void;
  upsell?: Upsell | null;
  servingsPerMeal?: number;
  cardType: CardType;
  format: MealPlanFormat;
  analyticsLocation: MealPlanAnalyticsLocation;
}

const MealPlanDishModal: React.FC<IMealPlanDishModal> = (props) => {
  const { cart, setItemQuantity } = useCartStoreSelectors();
  const trackCartChange = useMealPlanTrackingCartChange();
  const { foodItemIdAvailability, setFoodItemIdAvailability, getShefRemainingAvailability, addShefAvailability } =
    useSegmentStoreSelectors();

  const {
    foodItem,
    servingsPerMeal,
    shefSegment,
    upsell,
    onClose: handleClose = noOp,
    foodItemIndex,
    shefIndex,
    cardType,
    format,
    analyticsLocation,
  } = props;

  const unitsInCart = cart[foodItem.shef.id]?.cartItems.find((item) => item.foodItem.id === foodItem.id)?.quantity ?? 0;
  const minQuantityForModal = unitsInCart > 0 ? 0 : 1;

  const [formState, setFormState] = useState<IBaseDishModalFormState>({
    spiceLevelCustomizationId: DEFAULT_CUSTOMIZATION_ID,
    quantity: unitsInCart === 0 ? 1 : unitsInCart,
  });

  const formInitialState: IBaseDishModalFormState = useMemo(
    () => ({
      spiceLevelCustomizationId: DEFAULT_CUSTOMIZATION_ID,
      quantity: unitsInCart === 0 ? 1 : unitsInCart,
    }),
    [unitsInCart]
  );

  // Analytics
  const trackQuantityChange = (newQuantity: number) => {
    const shefPosition = isNil(shefIndex) ? undefined : shefIndex + 1;
    const foodItemPosition = foodItemIndex + 1;

    if (newQuantity > unitsInCart) {
      trackCartChange({
        foodItem,
        shefId: foodItem.shef.id,
        shefPosition,
        foodItemPosition,
        analyticsLocation: 'dish-modal',
        action: 'add',
      });
    } else if (newQuantity < unitsInCart) {
      trackCartChange({
        foodItem,
        shefId: foodItem.shef.id,
        shefPosition,
        foodItemPosition,
        analyticsLocation: 'dish-modal',
        action: 'remove',
      });
    }
  };

  const updateFoodAndShefAvailability = (newQuantity: number) => {
    if (newQuantity > unitsInCart) {
      const quantityAdded = newQuantity - unitsInCart;
      setFoodItemIdAvailability({ [foodItem.id]: foodItemIdAvailability[foodItem.id] - quantityAdded });
      const shefAvailabilityToRemove = getServingsPerMealForItem(foodItem.id, servingsPerMeal ?? 0);
      addShefAvailability(
        (getShefRemainingAvailability(foodItem.shef.id) ?? 0) - shefAvailabilityToRemove,
        foodItem.shef.id
      );
    } else if (newQuantity < unitsInCart) {
      const quantityRemoved = unitsInCart - newQuantity;
      setFoodItemIdAvailability({ [foodItem.id]: foodItemIdAvailability[foodItem.id] + quantityRemoved });
      const shefAvailabilityToAdd = getServingsPerMealForItem(foodItem.id, servingsPerMeal ?? 0);
      addShefAvailability(
        (getShefRemainingAvailability(foodItem.shef.id) ?? 0) + shefAvailabilityToAdd,
        foodItem.shef.id
      );
    }
  };

  const submit = useCallback(() => {
    const cartItem = {
      foodItem,
      quantity: formState.quantity,
    };
    setItemQuantity(cartItem, shefSegment);
    trackQuantityChange(formState.quantity);
    updateFoodAndShefAvailability(formState.quantity);
    setTimeout(handleClose, 250);
  }, [setItemQuantity, foodItem.id, foodItem.shef.id, formState.quantity, handleClose]);

  return (
    <BaseDishModal
      {...props}
      foodItem={foodItem}
      canSubmitCart={!isEqual(formState, formInitialState)}
      submit={submit}
      minQuantity={minQuantityForModal}
      productType='subscriptions'
      isEditingCart={unitsInCart > 0}
      unitsInCart={unitsInCart}
      formState={formState}
      upsell={upsell}
      showPortionSizeControls={false}
      setFormState={setFormState}
      cardType={cardType}
      format={format}
      servingsPerMeal={servingsPerMeal}
      location={analyticsLocation}></BaseDishModal>
  );
};

const WrapperMealPlanDishModal: React.FC<IMealPlanDishModal> = (props) => {
  const { data: foodItemData, loading } = useDishModalQuery({
    variables: { foodItemId: props.foodItem.id },
    skip: isNil(props.foodItem.shef.id),
  });

  if (loading) {
    return null;
  }

  return <MealPlanDishModal {...props} loadedData={foodItemData?.foodItem} />;
};

export default WrapperMealPlanDishModal;
