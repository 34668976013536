import { MAX_STARS } from './Constants';

/*
 * Stars are based on a scale from 0 to 100. Since the rating scale is based on 5 stars
 * each star is worth 20 or 20%. The utility functions on this file are based on this scale.
 */

/**
 * Converts a rating percentage (0 - 100%) to a stars value (0.00 - 5.00)
 */
export const percentToStars = (percent: number): number => (MAX_STARS * percent) / 100;

/**
 * Converts a stars value (0.00 - 5.00) to a rating percentage (0 - 100%)
 */
export const starsToPercent = (stars: number): number => stars * (100 / MAX_STARS);

/**
 * Converts a stars value (0.00 - 5.00) to a rating score (0.0 - 5.0)
 * Prefer vs .toFixed(1) to account for floating point inconsistencies: https://stackoverflow.com/q/661562/17264481
 * Optionally define a precision. Defaults to 1. Ex: precision of 2 on a rating of 4.6482 will return 4.65
 */
export const starsToRating = (stars: number, precision = 1): number => {
  const factorOfTen = 10 ** precision;
  return Math.round(stars * factorOfTen) / factorOfTen;
};

/**
 * Converts a rating percent (0 - 100) to a rating score based on 5 stars (0.0 - 5.0)
 */
export const adjustedStarsToRating = (stars: number, precision = 1): number => {
  const starValue = 20; // Each star is worth 20%
  const avgStarRating = stars / starValue;
  return starsToRating(avgStarRating, precision);
};

export const POSITIVE_RATING_PERCENT = 100;
export const NEGATIVE_RATING_PERCENT = 0;

export type PositiveRatingPercent = typeof POSITIVE_RATING_PERCENT;
export type NegativeRatingPercent = typeof NEGATIVE_RATING_PERCENT;
export type RatingPercent = PositiveRatingPercent | NegativeRatingPercent;

function ratingCountHash(input: number): number {
  return (Math.abs(input) % 6) + 5;
}

function ratingValueHash(input: number): number {
  return (Math.abs(input) % 6) + 94;
}

export function getMealPlanRating(ratingCount: number, averageRating: number, foodItemId: number): number {
  return ratingCount > 5 ? averageRating : ratingValueHash(foodItemId);
}

export function getMealPlanRatingCount(ratingCount: number, foodItemId: number): number {
  return ratingCount > 5 ? ratingCount : ratingCountHash(foodItemId);
}
