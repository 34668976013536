import { abbreviateNumber } from 'common/NumberUtils';
import { getMealPlanRating, getMealPlanRatingCount } from 'common/RatingUtilities';
import { isNil } from 'lodash';
import { Upsell } from 'src/gqlReactTypings.generated.d';
import { UpsellLabelChip } from 'src/pages/consumer/meal-plans/components/UpsellChips';
import { dietaryTagToIcon } from 'src/shared/design-system/DietaryTagIconSetV2/utils';
import { HorizontalSnapBadgeContainer } from 'src/shared/design-system/HorizontalSnapBadgeContainer';
import { ThumbsUpV2 } from 'src/shared/design-system/Icon';
import { VerticallyCenteredText } from 'src/shared/design-system/VerticallyCenteredText';
import styled from 'styled-components';
import { DietaryTags } from 'web-common/src/shared/Enums';
import { Colors, FontSize, Spacing } from 'web-common/src/shared/styles';

interface IFoodTagsProps {
  dietaryTags: DietaryTags[];
  averageRating?: number;
  ratingCount?: number;
  foodItemId?: number;
  upsell?: Upsell | null;
}

export const FoodBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: ${Spacing.QUARTER};
  height: ${Spacing.DOUBLE};
  padding-left: ${Spacing.HALF};
  padding-right: ${Spacing.HALF};

  font-size: ${FontSize.XS};
  color: ${Colors.GRAY_DARK_15};
`;

export const RatingBadge = styled(FoodBadge)`
  background: ${Colors.JACKFRUIT_LITE_30};
`;

export const DietaryBadge = styled(FoodBadge)`
  background: ${Colors.GRAY_LITE_30};
`;

export const ModalV2FoodTags: React.FC<IFoodTagsProps> = ({
  dietaryTags,
  averageRating,
  ratingCount,
  foodItemId,
  upsell,
}) => {
  const ratingCountToUse = getMealPlanRatingCount(ratingCount ?? 0, foodItemId ?? 5);
  const averageRatingToUse = getMealPlanRating(ratingCount ?? 0, averageRating ?? 0, foodItemId ?? 5);
  const dietaryBadges = dietaryTags.map((tag) => (
    <DietaryBadge key={`icon-${tag}`}>
      {dietaryTagToIcon[tag]}
      <VerticallyCenteredText className='ml-2 whitespace-nowrap'>{tag}</VerticallyCenteredText>
    </DietaryBadge>
  ));
  return (
    <HorizontalSnapBadgeContainer marginX={24}>
      {upsell && <UpsellLabelChip upsell={upsell} applyFullHeight />}
      {!isNil(averageRating) && !isNil(ratingCount) && (
        <RatingBadge key={'icon-rating'}>
          <ThumbsUpV2 width={16} height={16} className='mb-1 mr-1' />
          <VerticallyCenteredText className='ml-1 whitespace-nowrap'>
            {abbreviateNumber(averageRatingToUse)}% {ratingCountToUse ? ` (${abbreviateNumber(ratingCountToUse)})` : ``}
          </VerticallyCenteredText>
        </RatingBadge>
      )}
      {dietaryBadges}
    </HorizontalSnapBadgeContainer>
  );
};
