/**
 * This function checks if a property exists in the given object.
 * If the property exists, it returns the truthy value, otherwise it returns the falsy value.
 * @param {keyof T | (keyof T)[]} key - The key or keys to check in the object.
 * @param {U} truthy - The value to return if the property exists.
 * @param {V} falsy - The value to return if the property does not exist.
 * @returns {string} - The truthy or falsy value.
 */
export const ifProp =
  <T, U, V>(key: keyof T | (keyof T)[], truthy: U, falsy?: V) =>
  (props: T) => {
    let pass = false;
    if (Array.isArray(key)) {
      pass = key.filter((k) => props[k]).length === key.length;
    } else {
      pass = Boolean(props[key]);
    }

    const value = pass ? truthy : falsy;
    return value === undefined ? '' : value;
  };
