import { MealPlanAnalyticsLocation } from 'common/events/ClientEvents';
import { isMealPlanSideDish } from 'common/MealPlanUtils';
import React, { useMemo, useState } from 'react';
import { MealPlanFormat } from 'src/gqlReactTypings.generated.d';
import { useCartStoreSelectors } from 'src/ztore/cart-store';
import Chip, { ChipColor } from 'web-common/src/shared/design-system/components/Chip';
import WrapperMealPlanDishModal from '../components/MealPlanDishModal';
import { MealPlanDishCardLarge } from './MealPlanDishCardLarge';
import { MealPlanDishCardSmall } from './MealPlanDishCardSmall';
import { DishCardDisplayMode, MealPlanFoodItem, MealPlanShefSegment } from './types';

export type MealPlanDishCardUserPreferences = {
  requiredNumber: number;
  servingsPerMeal: number;
};

export enum CardType {
  Large = 'Large',
  Small = 'Small',
  SmallWithShefName = 'SmallWithShefName',
}

interface IProps {
  cardType: CardType;
  displayMode?: DishCardDisplayMode;
  foodItem: MealPlanFoodItem;
  foodItemIndex: number;
  hideServingCount?: boolean;
  // If true, the component will respect the number of weekly dishes / sides
  // Otherwise, it will allow the user to select as many dishes as they want
  limitDishSelection?: boolean;
  remainingAvailableUnits: number;
  requiredNumber: number;
  servingsPerMeal: number;
  format: MealPlanFormat;
  shefIndex?: number;
  shefSegment: MealPlanShefSegment;
  analyticsLocation: MealPlanAnalyticsLocation;
}

export const ServingChip: React.FC<{ numServings: number }> = ({ numServings }) => {
  const optionalPlural = numServings > 1 ? 's' : '';
  const copy = `${numServings} serving${optionalPlural}`;
  return <Chip color={ChipColor.CANTELOUPE_50} copy={copy} />;
};

export const PriceChip: React.FC<{ price: number; optionalText?: string }> = ({ price, optionalText = '' }) => {
  const copy = `$${price / 100} ${optionalText}`;
  return <Chip color={ChipColor.MINT_50} copy={copy} />;
};

export const MealPlanDishCard: React.FC<IProps> = ({
  cardType,
  displayMode = DishCardDisplayMode.Carousel,
  foodItem,
  foodItemIndex,
  hideServingCount,
  format,
  limitDishSelection = true,
  remainingAvailableUnits,
  requiredNumber,
  servingsPerMeal,
  shefIndex,
  shefSegment,
  analyticsLocation,
}) => {
  const { cart, getNumSelectedMains, getNumSelectedSides } = useCartStoreSelectors();
  const numSelectedMains = getNumSelectedMains();
  const numSelectedSides = getNumSelectedSides();
  const { id, shef, upsell } = foodItem;

  const numSelected = isMealPlanSideDish(id) ? numSelectedSides : numSelectedMains;

  const unitsInCart = cart[shef.id]?.cartItems.find((item) => item.foodItem.id === foodItem.id)?.quantity ?? 0;

  const selectedAllDishesForWeek = numSelected >= requiredNumber;
  const itemUnavailable = remainingAvailableUnits <= 0;

  const maxQuantityForSelection = Math.min(
    requiredNumber - numSelected + unitsInCart,
    unitsInCart + remainingAvailableUnits
  );
  const buttonsDisabled = useMemo(
    () => (itemUnavailable || limitDishSelection ? selectedAllDishesForWeek || itemUnavailable : false),
    [selectedAllDishesForWeek, itemUnavailable, limitDishSelection]
  );

  const [isDishDetailsModalOpen, setIsDishDetailsModalOpen] = useState(false);

  const openDishModal = () => {
    setIsDishDetailsModalOpen(true);
  };

  const closeDishModal = () => {
    setIsDishDetailsModalOpen(false);
  };

  const cardToRender =
    cardType === CardType.Large ? (
      <MealPlanDishCardLarge
        displayMode={displayMode}
        buttonsDisabled={buttonsDisabled}
        foodItem={foodItem}
        foodItemIndex={foodItemIndex}
        hideServingCount={hideServingCount}
        remainingAvailableUnits={remainingAvailableUnits}
        servingsPerMeal={servingsPerMeal}
        shefIndex={shefIndex}
        shefSegment={shefSegment}
        openDishModal={openDishModal}
        analyticsLocation={analyticsLocation}
      />
    ) : (
      <MealPlanDishCardSmall
        buttonsDisabled={buttonsDisabled}
        displayMode={displayMode}
        foodItem={foodItem}
        foodItemIndex={foodItemIndex}
        remainingAvailableUnits={remainingAvailableUnits}
        mealPlanNumServings={servingsPerMeal}
        format={format}
        shefIndex={shefIndex}
        shefSegment={shefSegment}
        openDishModal={openDishModal}
        analyticsLocation={analyticsLocation}
        showShef={cardType === CardType.SmallWithShefName}
      />
    );

  return (
    <>
      {cardToRender}
      {isDishDetailsModalOpen && (
        <WrapperMealPlanDishModal
          disabled={buttonsDisabled}
          foodItem={foodItem}
          isShowing={isDishDetailsModalOpen}
          maxQuantity={maxQuantityForSelection}
          shefSegment={shefSegment}
          showModalPrompt={false}
          format={format}
          cardType={cardType}
          servingsPerMeal={servingsPerMeal}
          upsell={upsell}
          onClose={closeDishModal}
          foodItemIndex={foodItemIndex}
          shefIndex={shefIndex}
          openModal={openDishModal}
          analyticsLocation={analyticsLocation}
        />
      )}
    </>
  );
};
