import { DateTime } from 'luxon';

export function isSameDate(date1: string, date2: string) {
  if (!date1 || !date2) return false;
  const date1DateTime = DateTime.fromISO(date1);
  const date2DateTime = DateTime.fromISO(date2);
  return (
    date1DateTime.day === date2DateTime.day &&
    date1DateTime.month === date2DateTime.month &&
    date1DateTime.year === date2DateTime.year
  );
}
