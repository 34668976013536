/* eslint-disable eslint-comments/disable-enable-pair -- facebook pixel code  */
/* eslint-disable prefer-destructuring -- facebook pixel code */
/* eslint-disable prefer-rest-params -- facebook pixel code  */
/* eslint-disable functional/functional-parameters -- facebook pixel code  */
/* eslint-disable prefer-spread -- facebook pixel code  */
/* eslint-disable no-param-reassign -- facebook pixel code  */
/* eslint-disable no-underscore-dangle -- facebook pixel code  */
/* eslint-disable func-names -- facebook pixel code  */
/* eslint-disable @typescript-eslint/no-unused-expressions -- facebook pixel code  */
/* eslint-disable no-multi-assign -- facebook pixel code  */
/* eslint-disable functional/immutable-data -- facebook pixel code  */
import { ApolloClient } from '@apollo/client';
import { ClientEvents, ClientTrackableEvents } from 'common/events/ClientEvents';
import { cleanFbData } from 'common/events/FacebookDataCleaner';
import { SharedEvents } from 'common/events/SharedEvents';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import LinkedInTag from 'react-linkedin-insight';
import { SingularConfig, singularSdk } from 'singular-sdk';
import {
  FacebookTrackEventDocument,
  FacebookTrackEventMutation,
  FacebookTrackEventMutationVariables,
} from 'src/gqlReactTypings.generated.d';
import { isProdEnvironment } from 'src/shared/utils/EnvironmentUtilities';
import { ITracker, UserInfo } from 'src/tracking/types';
import { gtagSetUserData, trackAdwordsEvent } from 'src/vendor/adwords';
import { v4 as uuid } from 'uuid';
import { GoogleTagManagerEventNames, ITagManagerInfo, sendInfoToGoogleTagManager } from '../googleTagManagerUtils';

const REACT_APP_SINGULAR_API_KEY = process.env.REACT_APP_SINGULAR_API_KEY;
const REACT_APP_SINGULAR_SECRET_KEY = process.env.REACT_APP_SINGULAR_SECRET_KEY;
const REACT_APP_SINGULAR_PRODUCT_ID = process.env.REACT_APP_SINGULAR_PRODUCT_ID;

const FACEBOOK_PIXEL_ID = '350875572167561';
const StandardFBEventNames = [
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout',
  'Lead',
  'PageView',
  'Purchase',
  'Schedule',
  'Search',
  'StartTrial',
  'SubmitApplication',
  'Subscribe',
  'ViewContent',
];

export class WebAdTracker<T> implements ITracker {
  userInfo?: UserInfo;

  fbclid?: string;

  hasReceivedFirstPageViewEvent = false;

  hasInitializedSingularSdk = false;

  debug = !isProdEnvironment();

  public constructor(public readonly browserTrackerId: string, private readonly apolloClient: ApolloClient<T>) {}

  public init() {
    const debug = !isProdEnvironment();

    const initializations: [string, () => void][] = [
      [
        'google analytics',
        () => {
          ReactGA.initialize('G-WCZT3E1FD5', { gaOptions: { debug_mode: debug }, gtagOptions: { debug_mode: debug } });
        },
      ],
      [
        'facebook',
        () => {
          const urlParams = new URLSearchParams(window.location.search);
          const fbclidParam = urlParams.get('fbclid');
          let fbcValue: string;
          if (fbclidParam) {
            // If already formatted, use it directly; otherwise, format it.
            fbcValue = fbclidParam.startsWith('fb.1.')
              ? fbclidParam
              : `fb.1.${Math.floor(Date.now() / 1000)}.${fbclidParam}`;
          } else {
            // Generate a new fbclid value if none exists.
            // Using uuid (imported from 'uuid') to generate a unique identifier.
            const generatedId = uuid();
            fbcValue = `fb.1.${Math.floor(Date.now() / 1000)}.${generatedId}`;
          }
          document.cookie = `_fbc=${fbcValue}; path=/;`;
          // New Facebook Pixel Code per Facebook's recommendations: https://developers.facebook.com/docs/meta-pixel/get-started
          (function (f: any, b: Document, e: string, v: string, n?: any, t?: HTMLScriptElement, s?: Element) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = true;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e) as HTMLScriptElement;
            t.async = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            // Use non-null assertion to let TS know parentNode exists.
            s.parentNode!.insertBefore(t, s);
          })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          window.fbq('init', FACEBOOK_PIXEL_ID);
          window.fbq('track', 'PageView');
        },
      ],
      [
        'linkedin',
        () => {
          LinkedInTag.init('3657748', 'px');
        },
      ],
      [
        'singular',
        () => {
          if (!REACT_APP_SINGULAR_API_KEY || !REACT_APP_SINGULAR_SECRET_KEY || !REACT_APP_SINGULAR_PRODUCT_ID) {
            throw new Error('Missing env variables for initializing Singular SDK');
          }

          const config = new SingularConfig(
            REACT_APP_SINGULAR_API_KEY,
            REACT_APP_SINGULAR_SECRET_KEY,
            REACT_APP_SINGULAR_PRODUCT_ID
          ).withInitFinishedCallback(() => {
            this.hasInitializedSingularSdk = true;
          });

          singularSdk.init(config);
        },
      ],
    ];

    initializations.forEach(([name, init]) => {
      try {
        init();
      } catch (error: unknown) {
        console.error(`Tracker initialization failed ${name}`, error);
      }
    });
  }

  public hasBeenIdentified(): boolean {
    return !!this.userInfo;
  }

  public updateIdentity<I extends keyof UserInfo>(updates: Pick<UserInfo, I>): void {
    if (!this.userInfo) {
      console.error(`updateIdentity called without userinfo initialized`);
      return;
    }

    this.userInfo = {
      ...this.userInfo,
      ...updates,
    };
  }

  public identify(userInfo: UserInfo) {
    this.userInfo = userInfo;

    const matching: {
      external_id: string;
      em: string;
      fn?: string;
      ln?: string;
      zp?: string;
    } = {
      external_id: userInfo.id,
      em: userInfo.email,
    };

    if (userInfo.firstName) {
      matching.fn = userInfo.firstName;
    }
    if (userInfo.lastName) {
      matching.ln = userInfo.lastName;
    }

    if (userInfo.lastSearchedZipCode) {
      matching.zp = userInfo.lastSearchedZipCode;
    }

    window.fbq?.('init', FACEBOOK_PIXEL_ID, matching);

    singularSdk.login(userInfo.id);
    gtagSetUserData(userInfo);
  }

  private fbTrack(eventName: string, data?: Record<string, any>) {
    const fbc = Cookies.get('_fbc');
    const fbp = Cookies.get('_fbp');
    const eventID: string = data?.eId ?? uuid();
    const cleanData = cleanFbData(data);
    const dataStringify = JSON.stringify(cleanData);

    // https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
    if (StandardFBEventNames.includes(eventName)) {
      window.fbq?.('track', eventName, cleanData, { eventID });
    } else {
      window.fbq?.('trackCustom', eventName, cleanData, { eventID });
    }
    if (this.debug) {
      console.info('[facebook-pixel]', ...[eventName, cleanData, { eventID }]);
    }

    this.apolloClient
      .mutate<FacebookTrackEventMutation, FacebookTrackEventMutationVariables>({
        mutation: FacebookTrackEventDocument,
        variables: {
          eventName,
          event: {
            eid: eventID,
            fbc,
            fbp,
            data: dataStringify,
          },
        },
      })
      .catch((e) => console.error(e));
  }

  public track<K extends keyof ClientTrackableEvents>(event: K, eventData: ClientTrackableEvents[K]): void {
    // This runs third-party tracker code that we have no control over. It must be wrapped in try/catch,
    // otherwise bugs in other people's code can break our site!
    try {
      const data = {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ...(eventData as any),
        zipCode: this.userInfo?.lastSearchedZipCode,
        btId: this.browserTrackerId,
        userId: this.userInfo?.id,
        userEmail: this.userInfo?.email,
        pagePath: window.location.pathname,
        pageQueryArgs: window.location.search,
        eId: uuid(),
      };

      const googleTagManagerArgs: ITagManagerInfo = {
        userId: data.userId,
        userEmail: data.userEmail,
        userLoggedIn: !!data.userId,
        pagePath: window.location.pathname,
        pageQueryArgs: window.location.search,
      };

      switch (event) {
        case ClientEvents.PURCHASE: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const purchaseData = data as ClientTrackableEvents[ClientEvents.PURCHASE];

          const subtotalInMajorUnits = purchaseData.subtotal / 100.0;
          // TODO: need to make sure that we don't need to make any changes to google tag manager for reporting group order id for transactionId & googleTagManagerArgs.orderId
          const transactionId = String(purchaseData.groupOrderId);

          googleTagManagerArgs.event = GoogleTagManagerEventNames.PURCHASE_CONVERSION;
          googleTagManagerArgs.conversionSubtotal = subtotalInMajorUnits;
          googleTagManagerArgs.orderId = `${purchaseData.groupOrderId}`;
          // For ad-affiliate networks they can only use one purhcase event, need to add
          // newUserPurchase field to differentiate for GTM
          googleTagManagerArgs.newUserPurchase = purchaseData.orderNumber === 1 ? 1 : 0;

          sendInfoToGoogleTagManager(googleTagManagerArgs);

          this.fbTrack('Purchase', { value: subtotalInMajorUnits, currency: 'USD' });

          ReactGA.event('purchase', {
            currency: 'USD',
            transaction_id: purchaseData.groupOrderId,
            value: subtotalInMajorUnits,
            coupon: purchaseData.promoCode,
            items: purchaseData.items.map((item) => ({
              item_id: item.foodItemId,
              quantity: item.quantity,
              affiliation: item.shefId,
            })),
          });
          trackAdwordsEvent(event, {
            currency: 'USD',
            value: subtotalInMajorUnits,
            transaction_id: transactionId,
          });

          LinkedInTag.track('5990484');

          singularSdk.revenue('Purchase', 'USD', subtotalInMajorUnits, {
            transaction_id: transactionId,
          });
          break;
        }
        case ClientEvents.NEW_USER_PURCHASE: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const purchaseData = data as ClientTrackableEvents[ClientEvents.NEW_USER_PURCHASE];
          const subtotalInMajorUnits = purchaseData.subtotal / 100.0;
          const transactionId = String(purchaseData.groupOrderId);
          this.fbTrack(ClientEvents.NEW_USER_PURCHASE, { value: subtotalInMajorUnits, currency: 'USD' });
          trackAdwordsEvent(event, {
            transaction_id: transactionId,
            currency: 'USD',
            value: subtotalInMajorUnits,
          });
          singularSdk.event(ClientEvents.NEW_USER_PURCHASE, { value: subtotalInMajorUnits, currency: 'USD' });

          googleTagManagerArgs.event = GoogleTagManagerEventNames.NEW_USER_PURCHASE;
          googleTagManagerArgs.conversionSubtotal = subtotalInMajorUnits;
          googleTagManagerArgs.orderId = `${purchaseData.groupOrderId}`;
          sendInfoToGoogleTagManager(googleTagManagerArgs);

          break;
        }
        case ClientEvents.EXISTING_USER_PURCHASE: {
          const purchaseData = data as ClientTrackableEvents[ClientEvents.EXISTING_USER_PURCHASE];
          const subtotalInMajorUnits = purchaseData.subtotal / 100.0;
          const transactionId = String(purchaseData.groupOrderId);
          googleTagManagerArgs.event = GoogleTagManagerEventNames.EXISTING_USER_PURCHASE;
          googleTagManagerArgs.conversionSubtotal = subtotalInMajorUnits;
          googleTagManagerArgs.orderId = `${purchaseData.groupOrderId}`;
          singularSdk.event(ClientEvents.EXISTING_USER_PURCHASE, { value: subtotalInMajorUnits, currency: 'USD' });
          this.fbTrack(event, data);

          trackAdwordsEvent(event, {
            transaction_id: transactionId,
            currency: 'USD',
            value: subtotalInMajorUnits,
          });
          sendInfoToGoogleTagManager(googleTagManagerArgs);
          break;
        }
        case ClientEvents.SUBSCRIPTION_PURCHASE: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const purchaseData = data as ClientTrackableEvents[ClientEvents.SUBSCRIPTION_PURCHASE];
          const subtotalInMajorUnits = purchaseData.subtotal / 100.0;
          this.fbTrack('Subscribe', { value: subtotalInMajorUnits, currency: 'USD' });
          break;
        }
        case ClientEvents.MEAL_PLANNING_MVP_CHECKOUT_STARTED: {
          this.fbTrack(event, data);
          trackAdwordsEvent(event, data);
          sendInfoToGoogleTagManager(googleTagManagerArgs);
          break;
        }
        case ClientEvents.MEAL_PLANNING_MVP_DELIVERY_ADDRESS_SELECT: {
          this.fbTrack(event, data);
          trackAdwordsEvent(event, data);
          sendInfoToGoogleTagManager(googleTagManagerArgs);
          break;
        }
        case ClientEvents.MEAL_PLANNING_MVP_PURCHASE_ATTEMPT: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const purchaseData = data as ClientTrackableEvents[ClientEvents.MEAL_PLANNING_MVP_PURCHASE_ATTEMPT];
          this.fbTrack(event, data);
          trackAdwordsEvent(event, {
            currency: 'USD',
            community: purchaseData.community,
            value: purchaseData.weeklySubtotal,
          });
          sendInfoToGoogleTagManager(googleTagManagerArgs);
          break;
        }
        case ClientEvents.MEAL_PLANNING_MVP_PURCHASE: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const purchaseData = data as ClientTrackableEvents[ClientEvents.MEAL_PLANNING_MVP_PURCHASE];
          this.fbTrack(event, data);

          googleTagManagerArgs.event = GoogleTagManagerEventNames.MEAL_PLAN_PURCHASE;
          googleTagManagerArgs.conversionSubtotal = purchaseData.finalPrice;
          trackAdwordsEvent(event, {
            currency: 'USD',
            community: purchaseData.community,
            value: purchaseData.weeklySubtotal,
          });
          sendInfoToGoogleTagManager(googleTagManagerArgs);
          break;
        }
        case ClientEvents.CART_ADD_ITEM: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const cartAddItemData = data as ClientTrackableEvents[ClientEvents.CART_ADD_ITEM];
          googleTagManagerArgs.event = GoogleTagManagerEventNames.CART_ADD_ITEM;
          sendInfoToGoogleTagManager(googleTagManagerArgs);
          trackAdwordsEvent(event, {
            currency: 'USD',
            value: cartAddItemData.price,
          });
          singularSdk.event(ClientEvents.CART_ADD_ITEM, { value: cartAddItemData.price, currency: 'USD' });
          this.fbTrack(event, data);
          break;
        }
        case ClientEvents.CART_REMOVE_ITEM: {
          this.fbTrack(event, data);
          break;
        }
        case ClientEvents.PAGE_VIEW: {
          // The Singular sdk automatically tracks the first page visit when it's initialized so we skip it
          // and only track every subsequent page view event.
          // https://support.singular.net/hc/en-us/articles/360039991491-Singular-Website-SDK-Native-Integration#2_Initializing_Singular
          if (this.hasReceivedFirstPageViewEvent && this.hasInitializedSingularSdk) {
            singularSdk.pageVisit();
          } else {
            this.hasReceivedFirstPageViewEvent = true;
          }

          this.fbTrack('PageView', data);
          googleTagManagerArgs.event = GoogleTagManagerEventNames.PAGE_VIEW;
          sendInfoToGoogleTagManager(googleTagManagerArgs);

          break;
        }
        case ClientEvents.REGISTER_SUCCESS:
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,no-case-declarations
          const typedData = data as ClientTrackableEvents[ClientEvents.REGISTER_SUCCESS];

          this.fbTrack(event, { fb_registration_method: typedData.method, ...typedData });
          trackAdwordsEvent(event, {});
          googleTagManagerArgs.event = GoogleTagManagerEventNames.REGISTER_SUCCESS;
          sendInfoToGoogleTagManager(googleTagManagerArgs);
          singularSdk.event(event, { fb_registration_method: typedData.method });
          break;
        case ClientEvents.SHEF_APPLICATION_RECEIVED:
          this.fbTrack(event, data);
          trackAdwordsEvent(event, {});
          googleTagManagerArgs.event = GoogleTagManagerEventNames.SHEF_APPLICATION_RECEIVED;
          sendInfoToGoogleTagManager(googleTagManagerArgs);
          singularSdk.event(ClientEvents.SHEF_APPLICATION_RECEIVED, {});
          break;
        case ClientEvents.FOOD_SEARCH_RESULTS_DISPLAYED: // Too high volume, unnecessary
        case ClientEvents.SESSION_STARTED: // Too high volume, unnecessary
        case SharedEvents.EXPERIMENT_EXPOSURE: // Too high volume, unnecessary
          break;
        default:
          this.fbTrack(event, data);
          trackAdwordsEvent(event, {});
      }
    } catch (err) {
      console.error(err);
    }
  }

  public onLogout(): void {
    this.userInfo = undefined;
    singularSdk.logout();
  }
}
