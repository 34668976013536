import { isNil } from 'lodash';
import React, { useMemo } from 'react';
import { MealPlanPreferences, MealPlanType, useGetSubscriptionQuery } from 'src/gqlReactTypings.generated.d';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { useConsumerMealPlanStore } from 'src/ztore/meal-plan-store';

interface IProps {
  userHasActiveMealPlan: boolean;
  mealPlanDataLoaded: boolean;
  mealPlan: Pick<
    MealPlanPreferences,
    'id' | 'nextDeliveryDate' | 'numMeals' | 'numServings' | 'format' | 'isReusablePackagingPlan'
  > | null;
  mealPlanType?: MealPlanType | null;
  mealPlanRegionId?: number | null;
  mealPlanZipCode?: string | null;
  refetchMealPlanData: () => void;
}

export const MealPlanSubscriptionContext = React.createContext<IProps>({
  userHasActiveMealPlan: false,
  mealPlanDataLoaded: false,
  mealPlan: null,
  refetchMealPlanData: () => {},
});

export const MealPlanSubscriptionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [currentUser, currentUserLoading] = useCurrentUser();
  const { setIsReusablePackaging, setMealPlanType, setNextDeliveryDate, setTasteProfile, setZipCode } =
    useConsumerMealPlanStore();

  const {
    data: subscriptionData,
    loading,
    called,
    refetch,
  } = useGetSubscriptionQuery({
    variables: {
      userId: currentUser?.id ?? '',
    },
    skip: !currentUser?.id,
    onCompleted: (data) => {
      if (data.getMealPlanForUser?.mealPlan) {
        const { mealPlanType, isReusablePackagingPlan } = data.getMealPlanForUser.mealPlan;
        setIsReusablePackaging(isReusablePackagingPlan);
        setMealPlanType(mealPlanType);
      }
      if (data.getMealPlanForUser?.mealPlanZipCode) setZipCode(data.getMealPlanForUser.mealPlanZipCode);
      if (data.getMealPlanForUser?.nextDeliveryDate) setNextDeliveryDate(data.getMealPlanForUser.nextDeliveryDate);
      if (data.getTasteProfile?.tasteProfile) {
        setTasteProfile(data.getTasteProfile.tasteProfile);
      }
    },
  });

  const { mealPlan, mealPlanRegionId, mealPlanZipCode, nextDeliveryDate } = subscriptionData?.getMealPlanForUser ?? {};

  const value = useMemo((): IProps => {
    const { mealPlanType, isReusablePackagingPlan } = mealPlan ?? {};
    const { unit: numMeals, numServings, format } = mealPlanType ?? {};

    return {
      userHasActiveMealPlan: !isNil(mealPlan) && called && !loading,
      mealPlanDataLoaded: (!currentUser && !currentUserLoading) || (!loading && called),
      mealPlanType,
      mealPlan:
        mealPlan?.mealPlanType && mealPlan?.id && numMeals && numServings && nextDeliveryDate
          ? {
              id: mealPlan.id,
              numMeals,
              numServings,
              nextDeliveryDate,
              format,
              isReusablePackagingPlan: isReusablePackagingPlan ?? false,
            }
          : null,
      refetchMealPlanData: refetch,
      mealPlanRegionId,
      mealPlanZipCode,
    };
  }, [mealPlan, loading, called, currentUser, currentUserLoading, refetch, mealPlanRegionId, mealPlanZipCode]);

  return <MealPlanSubscriptionContext.Provider value={value}>{children}</MealPlanSubscriptionContext.Provider>;
};
