import { ClientEvents, MealPlanAnalyticsLocation } from 'common/events/ClientEvents';
import { isMealPlanSideDish } from 'common/MealPlanUtils';
import { useLocation } from 'react-router-dom';
import { MealPlanFormat } from 'src/gqlReactTypings.generated.d';
import { MealPlanFoodItem } from 'src/pages/consumer/meal-plans/checkout/types';
import { useTracker } from 'src/shared/hooks/useTracker';
import { Routes } from 'src/shared/Routes';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { useConsumerMealPlanStore } from 'src/ztore/meal-plan-store';

interface TrackCartChangeParams {
  foodItem: MealPlanFoodItem;
  shefId: string;
  shefPosition?: number;
  foodItemPosition: number;
  analyticsLocation: MealPlanAnalyticsLocation;
  action: 'add' | 'remove';
}

/**
 * Hook for analytics tracking when adding or removing Meal Plan foodItems to/from cart.
 */
export const useMealPlanTrackingCartChange = () => {
  const tracker = useTracker();
  const location = useLocation();
  const dishSelectionRoute = locationInRoutes(location, [Routes.CONSUMER_MEAL_PLANS_EDIT]) ? 'edit' : 'checkout';
  const { getMealPlanType } = useConsumerMealPlanStore();
  const mealPlanFormat = getMealPlanType()?.format ?? MealPlanFormat.FlexibleSides;

  /**
   * Helper: analytics tracking when adding a foodItem to cart.
   * There are different events tracked for main dishes vs. side/staple dishes.
   */
  const trackAdd = ({ foodItem, shefId, shefPosition, foodItemPosition, analyticsLocation }) => {
    const foodItemId = foodItem.id;
    const isSideFoodItem = isMealPlanSideDish(foodItemId);

    if (isSideFoodItem) {
      tracker.track(ClientEvents.MEAL_PLANNING_MVP_SIDE_OR_STAPLE_ADD, {
        item: foodItemId,
        path: dishSelectionRoute,
        location: analyticsLocation,
        format: mealPlanFormat,
      });
    } else {
      tracker.track(ClientEvents.MEAL_PLANNING_MVP_ADD_TO_CART, {
        foodItemId: String(foodItemId),
        shefId,
        shefPosition,
        foodItemPosition,
        path: dishSelectionRoute,
        format: mealPlanFormat,
        location: analyticsLocation,
        // These are for CIO tracking
        foodItemDescription: foodItem.description ?? '',
        foodItemName: foodItem.name ?? '',
        foodItemImageUrl: foodItem.imageUrl ?? '',
        shefName: foodItem.shef.publicName,
      });
    }
  };

  /**
   * ########################################################
   *                    Main function
   * ########################################################
   */
  const trackCartChange = ({
    foodItem,
    shefId,
    shefPosition,
    foodItemPosition,
    analyticsLocation,
    action,
  }: TrackCartChangeParams) => {
    switch (action) {
      case 'add':
        trackAdd({
          foodItem,
          shefId,
          shefPosition,
          foodItemPosition,
          analyticsLocation,
        });
        break;
      case 'remove':
        tracker.track(ClientEvents.MEAL_PLANNING_MVP_REMOVE_FROM_CART, {
          foodItemId: String(foodItem.id),
          shefId,
          shefPosition,
          foodItemPosition,
          path: dishSelectionRoute,
          format: mealPlanFormat,
          location: analyticsLocation,
        });
        break;
      default:
        break;
    }
  };

  return trackCartChange;
};
