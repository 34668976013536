import { ClientEvents, LoginModalDismissElements } from 'common/events/ClientEvents';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { CheckoutFormUrls } from 'src/pages/consumer/meal-plans/checkout/consts';
import { ModalAnimation } from 'src/shared/design-system/Modal/utils';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { useHistory } from 'src/shared/hooks/useHistory';
import { useTracker } from 'src/shared/hooks/useTracker';
import { Routes } from 'src/shared/Routes';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';
import { StyledModal } from 'web-common/src/shared/design-system/components/Modal';
import { LoginOrSignUpModalContent } from './LoginOrSignUpModalContent';
import { useShowLoginOrSignUpModal } from './useShowLoginOrSignUpModal';

interface LoginOrSignUpModalProps {
  hide: () => void;
  isShowing: boolean;
  animation?: ModalAnimation;
}

export const LoginOrSignUpModal: React.FC<LoginOrSignUpModalProps> = ({ isShowing }) => {
  const [currentUser, currentUserIsLoading] = useCurrentUser();
  const { isSmallScreen } = useScreenContext();
  const tracker = useTracker();
  const { isClosable, signUpEntryPoint, handleCloseButtonClicked, loginOrSignUpModalEntryFormRef, entryHeader } =
    useShowLoginOrSignUpModal();
  const [modalHeaderText, setModalHeaderText] = useState<string>(entryHeader ?? 'LOG IN OR SIGN UP');
  const [{ step }] = useQueryParams({
    step: withDefault(StringParam, undefined),
  });
  const history = useHistory();
  const onMealPlanCheckoutPage = locationInRoutes(history.location, [Routes.CONSUMER_MEAL_PLANS_CHECKOUT]);
  const onMealPlanCheckoutStep = step === encodeURIComponent(CheckoutFormUrls.CHECKOUT) && onMealPlanCheckoutPage;

  useEffect(() => {
    if (isShowing) {
      tracker.track(ClientEvents.LOGIN_MODAL_OPENED, { signUpEntryPoint });
    }
  }, [isShowing, tracker, signUpEntryPoint]);

  const handleClose = (element: LoginModalDismissElements) => {
    if (onMealPlanCheckoutStep && loginOrSignUpModalEntryFormRef.current !== null) {
      history.push(`${Routes.CONSUMER_MEAL_PLANS_CHECKOUT}?step=${encodeURIComponent(CheckoutFormUrls.REVIEW)}`);
    } else {
      handleCloseButtonClicked(element);
    }
  };
  const secondaryButtonText =
    loginOrSignUpModalEntryFormRef.current === null || isClosable || onMealPlanCheckoutStep ? 'Back' : undefined;
  const headerTextStyle = isSmallScreen ? 'text-h4-bold' : 'text-h3';

  return (
    <StyledModal
      headerText={modalHeaderText}
      headerTextStyle={headerTextStyle}
      isShowing={isShowing && !currentUserIsLoading && isNil(currentUser)}
      showCloseButton={isClosable}
      removeContainerPadding
      removeHeaderPadding={false}
      secondaryButtonText={secondaryButtonText}
      centerHeaderText={true}
      onClose={() => handleClose(LoginModalDismissElements.BACK_BUTTON)}>
      <LoginOrSignUpModalContent
        showClose={isClosable}
        onCloseButtonClicked={handleClose}
        stickyHeader
        loginOrSignUpModalEntryFormRef={loginOrSignUpModalEntryFormRef}
        setModalHeaderText={setModalHeaderText}
      />
    </StyledModal>
  );
};
