import { Stripe, StripeElements } from '@stripe/stripe-js';
import { SpiceLevel } from 'common/SpiceLevels';
import { DateTime } from 'luxon';
import { UseControllerReturn } from 'react-hook-form';
import {
  CuisineCategory,
  DietaryRestriction,
  DietType,
  FoodItemAvailability,
  FoodItemCapacityForDeliveryDate,
  FoodItemCustomization,
  FoodItemQuantity,
  Maybe,
  MealPlanDishCardFoodItemFragment,
  MealPlanFormat,
  MealPlanOrderStatus,
  MealPlanPreferences,
  MealPlanType,
  Packaging,
  Protein,
  PublicShef,
  QueryGetMealPlanningMenuArgs,
  ShefRating,
  TasteProfile,
  Upsell,
} from 'src/gqlReactTypings.generated.d';

export enum DishCardDisplayMode {
  Carousel = 'Carousel',
  Grid = 'Grid',
}

export type ZipCodeOnly = {
  zipCode: string | undefined | null;
};

export type MealPlanShef = Pick<PublicShef, 'id' | 'publicName' | 'flakeId' | 'totalOrders' | 'imageUrl'> & {
  rating?: Pick<ShefRating, 'score' | 'count'> | null;
  shefProfile?: {
    bio?: string | null;
    cuisineCategories?: {
      id: string;
      title: string;
    }[];
  };
};

export interface MealPlanFoodItem {
  __typename?: 'FoodItem';
  id: number;
  name?: string | null;
  imageUrl?: string | null;
  averageRating: number;
  ratingCount: number;
  cuisineCategories: Pick<CuisineCategory, 'title' | 'id' | 'type'>[];
  shef: Pick<PublicShef, 'publicName' | 'id'>;
  spiceLevel: SpiceLevel;
  portion?: string | null;
  servingSize?: string | null;
  ingredients?: string | null;
  description?: string | null;
  price: number;
  vegetarian?: boolean | null;
  vegan?: boolean | null;
  glutenFree?: boolean;
  dairyFree?: boolean;
  isHalal?: boolean;
  isOrganic?: boolean;
  lowFodmap?: boolean | null;
  paleo?: boolean | null;
  whole30?: boolean | null;
  kosher?: boolean | null;
  packaging?: Packaging;
  availability?: Maybe<Array<FoodItemAvailability>>;
  slug?: string;
  spiceLevelCustomizations?: Maybe<Array<FoodItemCustomization>>;
  servingSizeCustomizations?: Maybe<Array<FoodItemCustomization>>;
  upsell?: Upsell | null;
}

export interface MealPlanShefSegment {
  foodItems: MealPlanFoodItem[];
  shef: MealPlanShef;
  shefRemainingAvailability: number;
}

/** A list of rank groups of shef segments
 * First is same shef as the cart,
 * Second is shefs matching the 'primary cuisine' of an item in cart
 * Third is the rest of the shefs
 */
export type ShefSegmentRankGroups = MealPlanShefSegment[][];

export type Community = 'indianmp' | 'yafdinkmp';

export interface MealPlanPreferencesStorageData {
  deliveryDate: string;
  cuisinePreferences?: string[] | null;
  dietType?: DietType;
  replacementDietaryRestrictions?: DietaryRestriction[];
  menuDietaryRestrictions?: DietaryRestriction[];
  preferredProteins?: Protein[] | null;
  mealPlanTypeId?: number | undefined;
  numAdults?: number;
  numLittleEaters?: number;
  numBigEaters?: number;
}

export interface MealSelectionAndCheckoutProps {
  community?: Community;
  userPreferences: MealPlanPreferencesStorageData;
  mains: MealPlanShefSegment[];
  sides: MealPlanShefSegment[];
  refetchMealPlanningMenu: (variables?: QueryGetMealPlanningMenuArgs) => void;
}

export interface MealPlanForUserData {
  nextDeliveryDate: string;
  numMeals: number;
  numServings: number;
  subscription: MealPlanPreferences;
  mealPlanOrderStatus: MealPlanOrderStatus;
}

export interface MealEditProps {
  community?: Community;
  foodItemCapacityForDeliveryDate: FoodItemCapacityForDeliveryDate[];
  cartLineItemUnits: FoodItemQuantity[];
  mainDishes: Pick<MealPlanDishCardFoodItemFragment, 'id'>[];
  mealPlanGroupOrderId?: string | null;
  mealPlanOrderStatus: MealPlanOrderStatus;
  mains: MealPlanShefSegment[];
  sides: MealPlanShefSegment[];
  sideDishes: Pick<MealPlanDishCardFoodItemFragment, 'id'>[];
  sidesPerWeek: number;
  subscription: Pick<
    MealPlanPreferences,
    'numMeals' | 'numServings' | 'nextDeliveryDate' | 'id' | 'isReusablePackagingPlan'
  >;
  tasteProfile: TasteProfile;
  refetchMealPlanningMenu: (variables?: QueryGetMealPlanningMenuArgs) => void;
}

type Selection = {
  [id: string]: number;
};

export interface DishSelectionFormValues {
  dishes: Selection;
  sides: Selection;
}

export interface MealPlanAddSidesProps {
  sideSelectionInput: UseControllerReturn<Partial<DishSelectionFormValues>, 'sides'>;
  totalSides?: number;
  requiredNumSides?: number;
  editing?: boolean;
  isPastCutoffDate?: boolean;
  heading?: string;
}

export interface MealPlanReviewOrderProps {
  mealPlanFormat: MealPlanFormat;
  mealPlanType: MealPlanType;
  previousStep: () => void;
  deliveryDate: string;
  deliveryTimeWindow?: { deliveryTimeWindowStart: number; deliveryTimeWindowEnd: number };
  showComprehensionSection?: boolean;
  isFirstOrder?: boolean;
}

export interface WrappedMealPlanFinalizeProps {
  community: Community;
  mealPlanFormat: MealPlanFormat;
  mealPlanType: MealPlanType;
  userPreferences: MealPlanPreferencesStorageData;
  paymentDetailsRef: React.RefObject<HTMLParagraphElement>;
  previousStep: () => void;
}

export interface MealPlanFinalizeProps extends WrappedMealPlanFinalizeProps {
  elements: StripeElements;
  stripe: Stripe;
}

export interface DishSelectionSummaryProps {
  deliveryDateTime: DateTime | null;
  numEntrees: number;
  numServings: number;
  mealPlanFormat?: MealPlanFormat | null;
}
