import cn from 'classnames';
import React, { useMemo } from 'react';

const IMAGE_URL = 'https://s3.us-west-1.amazonaws.com/myshef.com/static/media/whisk.gif';

interface LoadingProps {
  loadingMessage?: string;
}

const LoadingDots: React.FC = () => {
  const array = useMemo(() => new Array(3).fill(null), []);
  return (
    <>
      {array.map((_, idx) => (
        <span
          key={`LoadingDots[${idx}]`}
          className={cn('ml-1 inline-block size-2 animate-bounce rounded-full bg-eggplant-500 text-h1', {
            '[animation-delay:-0.3s]': idx === 0,
            '[animation-delay:-0.15s]': idx === 1,
          })}
        />
      ))}
    </>
  );
};

/**
 * Loading component that displays a loading message and a loading animation
 */
export const Loading: React.FC<LoadingProps> = ({ loadingMessage }) => (
  <div className='flex flex-col items-center justify-center gap-8'>
    <img src={IMAGE_URL} className='h-40' alt='' />
    <div className='px-4'>
      <p className='m-0 text-center font-quick-writers text-h3 text-eggplant-500'>
        {loadingMessage}
        <LoadingDots />
      </p>
    </div>
  </div>
);

/**
 * Loading screen that covers the entire screen centering the Loading content on the page
 */
export const LoadingScreen: React.FC<LoadingProps> = ({ loadingMessage }) => (
  <div className='fixed inset-0 flex min-h-96 flex-col items-center justify-center gap-8'>
    <Loading loadingMessage={loadingMessage} />
  </div>
);

/**
 * Loading screen that takes up the full height of the screen to push the footer down
 * when page is loading so that the loading screen doesn't overlap content
 */
export const LoadingScreenWithHeight: React.FC<LoadingProps> = ({ loadingMessage }) => (
  <div className='flex h-96 items-center justify-center'>
    <Loading loadingMessage={loadingMessage} />
  </div>
);
