import { getMealPlanRating, getMealPlanRatingCount } from 'common/RatingUtilities';

interface IProps {
  ratingCount: number;
  averageRating: number;
  foodItemId: number;
}

export const FoodItemRatings: React.FC<IProps> = ({ ratingCount, averageRating, foodItemId }) => (
  <>
    <p className='ml-1 text-caption-600 font-bold text-eggplant'>
      {getMealPlanRating(ratingCount, averageRating, foodItemId)}%
    </p>
    <p className='ml-1 text-label-400 text-eggplant-300'>({getMealPlanRatingCount(ratingCount, foodItemId)})</p>
  </>
);
