// React Hook Form consts
export const mode = 'all';
export const reValidateMode = 'onChange';

// Human readable step names for breadcrumbs
export enum CheckoutFormSteps {
  MEALS = 'Meals',
  SIDES = 'Sides',
  REVIEW = 'Review',
  CHECKOUT = 'Checkout',
}

// Strings for query param navigation
export enum CheckoutFormUrls {
  MEALS = 'meals',
  SIDES = 'sides',
  REVIEW = 'review',
  CHECKOUT = 'checkout',
}

type StepDetails = {
  name: CheckoutFormSteps;
  url: CheckoutFormUrls;
};

// This is the order of the steps. If you need to adjust the order, do it here and it will reflect where relevant
export const STEPS: StepDetails[] = [
  { name: CheckoutFormSteps.MEALS, url: CheckoutFormUrls.MEALS },
  { name: CheckoutFormSteps.SIDES, url: CheckoutFormUrls.SIDES },
  { name: CheckoutFormSteps.REVIEW, url: CheckoutFormUrls.REVIEW },
  { name: CheckoutFormSteps.CHECKOUT, url: CheckoutFormUrls.CHECKOUT },
];

// Given a url string, return the index of the corresponding step (for use in next/prev callbacks)
export const getStepIndexByUrl = (url: string) => STEPS.findIndex((step) => step.url === decodeURIComponent(url));

// Given a url string, return the name of the corresponding step (for use in Breadcrumbs)
export const getStepNameByUrl = (url: string) => STEPS.find((step) => step.url === decodeURIComponent(url))?.name;

export const LOADING_COPY = {
  indianmp: 'Loading homemade meals you’re going to love...',
  yafdinkmp: 'Loading personal shefs you’re going to love...',
};

export type SideDishInfo = {
  id: string;
  imageUrl: string;
  title: string;
  servings: string;
};

const BASMATI_LONG_GRAIN_RICE: SideDishInfo = {
  id: 'basmati-long-grain-rice',
  imageUrl:
    'https://cdn.t.shef.com/unsafe/500x334/center/middle/https://shef-general.s3.amazonaws.com/uploads/90a797bd-be8c-46e4-856c-d343647cf83a_1f819f2b-763d-4931-89f3-25be7e7bf0d0.jpg',
  title: 'Basmati long grain rice',
  servings: '12oz',
};
const ROTI: SideDishInfo = {
  id: 'roti',
  imageUrl:
    'https://cdn.t.shef.com/unsafe/250x169/center/middle/https://shef-general.s3.amazonaws.com/uploads/f1d239d3-93e2-47c8-bf7b-ddc1781c439f_rotis.jpg',
  title: 'Roti',
  servings: '3 pieces',
};
const JEERA_RICE: SideDishInfo = {
  id: 'rice',
  imageUrl:
    'https://cdn.t.shef.com/unsafe/500x334/center/middle/https://shef-general.s3.amazonaws.com/uploads/8cf43a70-68cf-4e28-9021-7e0d4f180968_7dd381c9-c452-410f-b4ef-de6de842dab2.jpg',
  title: 'Jeera rice',
  servings: '12oz',
};
const NAAN: SideDishInfo = {
  id: 'naan',
  imageUrl:
    'https://cdn.t.shef.com/unsafe/500x334/center/middle/https://shef-general.s3.amazonaws.com/uploads/11ee11f0-5c7d-437d-94a2-e3a3684463a9_PlainNaan.jpg',
  title: 'Naan',
  servings: '2 pieces',
};
const JASMINE_RICE: SideDishInfo = {
  id: 'jasmine-rice',
  title: 'Jasmine rice',
  imageUrl:
    'https://cdn.t.shef.com/unsafe/500x334/center/middle/https%3A/shef-general.s3.amazonaws.com/uploads/86f69fa7-8b74-4059-814d-e8d26a1b1b2b_43fa60f8-8210-46d3-8fde-f3c84ff8bbd7.jpeg',
  servings: '12oz',
};

export type SideDishInfoById = {
  [id: number]: SideDishInfo;
};

export const SIDE_DISH_INFO_BY_ID = {
  254691: BASMATI_LONG_GRAIN_RICE,
  254693: JEERA_RICE,
  254694: NAAN,
  254695: ROTI,
  265441: JASMINE_RICE,
  268265: BASMATI_LONG_GRAIN_RICE,
  268267: ROTI,
  268269: JEERA_RICE,
  255245: BASMATI_LONG_GRAIN_RICE,
  254699: JEERA_RICE,
  254700: ROTI,
  260006: BASMATI_LONG_GRAIN_RICE,
  260015: ROTI,
  260195: JEERA_RICE,
};

export const MEAL_PLAN_SIDE_OPTIONS = [BASMATI_LONG_GRAIN_RICE, ROTI, JEERA_RICE, NAAN, JASMINE_RICE];

export const MY_MENU_FILTER = {
  cuisineFilter: 'MY_MENU',
  title: 'My Menu',
  rootCuisineFilter: '',
  isSelected: false,
};
