import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { offsetLimitPagination, relayStylePagination } from '@apollo/client/utilities';
import { getGQLHeaders } from 'common/cookies/CookieUtils';
import { GqlRequestSource } from 'common/GqlRequestSource';
import { StorageKeys } from 'common/storage/constants';
import { sha256 } from 'src/crypto-web';
import { getSession } from '../tracking/tracking';
import { loadPrefsFromStorage } from '../user-preferences/userPreferencesState';
import { UserPreferences } from '../user-preferences/userPreferencesTypes';
import { BUILD_TIMESTAMP } from './hooks/useApiSyncHook';
import { getBrowserStorage, getToken } from './storage/Storage';
import { isDevEnvironment } from './utils/EnvironmentUtilities';
import { mergeCuisineResults, mergeDishResults, mergeShefResults } from './utils/GetSearchResultsMergeUtils';

// Easier debugging on mobile devices, where hitting 'localhost' would not work without some funky
// mobile hostfile voodoo. Instead, we use the hostname (the dev machine's IP)
const apiUrl =
  isDevEnvironment() && window.location.hostname !== 'localhost'
    ? (process.env.REACT_APP_GQL_API_URL || '').replace('localhost', window.location.hostname)
    : process.env.REACT_APP_GQL_API_URL;

const httpLink = createHttpLink({
  uri: apiUrl,
  credentials: 'include', // allows cookies
});

const retryLink = new RetryLink({
  delay: {
    initial: 250,
    max: 2500,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error: any, _operation: Operation) => {
      const doNotRetryCodes = [500, 400];
      return !!error && !doNotRetryCodes.includes(error.statusCode);
    },
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const urlLink = setContext((_, { headers }) => {
  const { href } = window.location;

  return {
    headers: {
      ...headers,
      'x-shef-href': href,
    },
  };
});

const clientSchemaVersionLink = setContext((_, { headers }) => {
  if (!BUILD_TIMESTAMP) {
    return {
      headers,
    };
  }

  return {
    headers: {
      ...headers,
      'x-version': BUILD_TIMESTAMP,
    },
  };
});

const browserVariantOverridesLink = setContext((_, { headers }) => {
  const storage = getBrowserStorage();
  let items;
  try {
    items = JSON.parse(storage.getItem(StorageKeys.VARIANT_OVERRIDES) || '{}');
  } catch (err) {
    console.error(err);
  }

  if (!items)
    return {
      headers,
    };

  return {
    headers: {
      ...headers,
      'x-vo': JSON.stringify(items),
    },
  };
});

const datadogLink = setContext((_, { headers }) => {
  const datadogSessionId = window.DD_RUM?.getInternalContext()?.session_id;

  if (!datadogSessionId) {
    return {
      headers,
    };
  }

  return {
    headers: {
      ...headers,
      'x-datadog-url': `https://app.datadoghq.com/rum/replay/sessions/${datadogSessionId}`,
    },
  };
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

interface CreateGqlClientOptions {
  source: GqlRequestSource;
  browserTrackerId: string;
}

export type GqlClient = ApolloClient<NormalizedCacheObject>;

export function createGqlClient({ source, browserTrackerId }: CreateGqlClientOptions): GqlClient {
  const browserTrackerLink = setContext((_, { headers }) => {
    const raw = loadPrefsFromStorage();

    const session = getSession() ?? undefined;
    const newHeaders = getGQLHeaders(browserTrackerId, source, session);
    return {
      headers: {
        ...headers,
        ...newHeaders,
        'x-zid': raw[UserPreferences.ZIPCODE],
      },
    };
  });

  const chain = browserTrackerLink
    .concat(urlLink)
    .concat(browserVariantOverridesLink)
    .concat(datadogLink)
    .concat(clientSchemaVersionLink)
    .concat(authLink)
    .concat(retryLink)
    .concat(httpLink);

  const browserSupportsCrypto = Boolean(window.crypto?.subtle);
  const link =
    browserSupportsCrypto && !isDevEnvironment() // Turn off persisted queries in dev so we can debug easier
      ? createPersistedQueryLink({
          sha256,
          useGETForHashedQueries: true,
        }).concat(chain)
      : chain;

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            exploreFoodItems: offsetLimitPagination(['zipCode', 'filters', 'seed']),
            searchSegments: {
              keyArgs: ['query', 'filters'],
            },

            // Cache redirect: https://www.apollographql.com/docs/react/caching/advanced-topics#cache-redirects
            // No reason to believe that foodItem will change substantially between initial loads and refetches in
            // other queries
            foodItem: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'FoodItem',
                  id: args?.id,
                });
              },
            },
            shef: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'PublicShef',
                  id: args?.id,
                });
              },
            },
            previousGroupOrders: relayStylePagination(),
            upcomingGroupOrders: relayStylePagination(),
            activeSubscriptions: relayStylePagination(),
            publicReviews: {
              keyArgs: ['shefId', 'requireComments'],
              merge: (existing, incoming, { args }) => {
                if (!existing) {
                  return { ...incoming };
                }

                const { offset = 0 } = args ?? {};
                const reviews = [
                  ...existing.reviews.slice(0, offset),
                  ...incoming.reviews,
                  ...existing.reviews.slice(offset + incoming.reviews.length),
                ];

                return { ...existing, reviews };
              },
            },
            getSearchResults: {
              keyArgs: ['query', 'filters', 'zipCode'],
              merge: (existing, incoming) => {
                if (!existing) {
                  return { ...incoming };
                }
                const {
                  cuisineResults: prevCuisineResults,
                  dishResults: prevDishResults,
                  shefResults: prevShefResults,
                } = existing;
                const { cuisineResults, dishResults, searchStateResult, shefResults } = incoming;

                const newCuisineResults = mergeCuisineResults(prevCuisineResults, cuisineResults);
                const newDishResults = mergeDishResults(prevDishResults, dishResults);
                const newShefResults = mergeShefResults(prevShefResults, shefResults);

                return {
                  ...existing,
                  cuisineResults: newCuisineResults,
                  dishResults: newDishResults,
                  searchStateResult,
                  shefResults: newShefResults,
                };
              },
            },
          },
        },
        ExploreSegment: {
          keyFields: ['id', 'cacheKey'],
          fields: {
            content: {
              keyArgs: ['filter'],
            },
          },
        },
        ExploreFoodItemsSegment: {
          keyFields: ['id', 'cacheKey'],
          fields: {
            content: {
              keyArgs: ['filter'],
            },
          },
        },
        ZipCode: {
          keyFields: ['zipCode'],
        },
      },
    }),
    defaultOptions,
  });
}
